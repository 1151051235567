import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FadeLoader } from 'react-spinners';

const Features = () => {
  // Refs for scrolling
  const marketRef = useRef(null);
  const propertyRef = useRef(null);
  const developmentRef = useRef(null);
  const investmentRef = useRef(null);
  const aiRef = useRef(null);
  
  // Scroll animation for elements
  useEffect(() => {
    const handleScroll = () => {
      const fadeElements = document.querySelectorAll('.fade-in');
      fadeElements.forEach(element => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        
        if (elementTop < windowHeight - 100) {
          element.classList.add('visible');
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  // Scroll to specific section
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  return (
    <>
      <Helmet>
        <title>Winpra | Features & Capabilities</title>
        <meta name="description" content="Explore the powerful features of Winpra's real estate analytics platform" />
      </Helmet>
      
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-primary-dark to-primary-light text-white pt-32 pb-20 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full opacity-10">
            <svg width="100%" height="100%" viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <pattern id="smallGrid" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect width="20" height="20" fill="none" stroke="white" strokeWidth="0.5"/>
                </pattern>
                <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
                  <rect width="80" height="80" fill="url(#smallGrid)"/>
                  <rect width="80" height="80" fill="none" stroke="white" strokeWidth="1"/>
                </pattern>
              </defs>
              <rect width="100%" height="100%" fill="url(#grid)" />
            </svg>
          </div>
        </div>
        
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6 fade-in">
              Powerful Real Estate Analytics Features
            </h1>
            <p className="text-xl md:text-2xl mb-10 fade-in" style={{ transitionDelay: '0.1s' }}>
              Discover how Winpra's cutting-edge features help you make smarter real estate decisions
            </p>
            
            <div className="flex flex-wrap justify-center gap-4 fade-in" style={{ transitionDelay: '0.2s' }}>
              <button 
                onClick={() => scrollToSection(marketRef)}
                className="px-6 py-2 bg-white bg-opacity-20 rounded-lg hover:bg-opacity-30 transition-all duration-300"
              >
                Market Intelligence
              </button>
              <button 
                onClick={() => scrollToSection(propertyRef)}
                className="px-6 py-2 bg-white bg-opacity-20 rounded-lg hover:bg-opacity-30 transition-all duration-300"
              >
                Property Valuation
              </button>
              <button 
                onClick={() => scrollToSection(developmentRef)}
                className="px-6 py-2 bg-white bg-opacity-20 rounded-lg hover:bg-opacity-30 transition-all duration-300"
              >
                Development Planning
              </button>
              <button 
                onClick={() => scrollToSection(investmentRef)}
                className="px-6 py-2 bg-white bg-opacity-20 rounded-lg hover:bg-opacity-30 transition-all duration-300"
              >
                Investment Analytics
              </button>
              <button 
                onClick={() => scrollToSection(aiRef)}
                className="px-6 py-2 bg-white bg-opacity-20 rounded-lg hover:bg-opacity-30 transition-all duration-300"
              >
                AI Capabilities
              </button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Feature Overview */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Transforming Real Estate Analytics
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our platform combines advanced AI, machine learning, and data analytics to provide unprecedented insights into real estate markets.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 fade-in">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-chart-line text-blue-600 text-2xl"></i>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-900 text-center">Data-Driven Insights</h3>
              <p className="text-gray-600 text-center">
                Comprehensive market intelligence based on millions of data points from diverse sources
              </p>
            </div>
            
            <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.1s' }}>
              <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-brain text-purple-600 text-2xl"></i>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-900 text-center">Predictive Analytics</h3>
              <p className="text-gray-600 text-center">
                Advanced forecasting for market trends, property values, and investment returns
              </p>
            </div>
            
            <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.2s' }}>
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-cogs text-green-600 text-2xl"></i>
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-900 text-center">Intelligent Automation</h3>
              <p className="text-gray-600 text-center">
                AI-powered processes that streamline analysis, reporting, and decision-making
              </p>
            </div>
          </div>
        </div>
      </section>
      
      {/* Market Intelligence Section */}
      <section ref={marketRef} id="market-intelligence" className="py-20">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Market Intelligence
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Access comprehensive real-time market data and insights to make informed real estate decisions
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Feature Card 1 */}
            <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                <i className="fas fa-chart-bar text-blue-600 text-xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">Real-time Market Data</h3>
                <p className="text-gray-600 mb-4 text-left">
                  Access up-to-the-minute market trends, metrics, and analytics with automated data collection from multiple sources.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Price trend monitoring
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Inventory tracking
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Days-on-market analysis
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature Card 2 */}
            <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.1s' }}>
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                <i className="fas fa-users text-indigo-600 text-xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">Demographic Analysis</h3>
                <p className="text-gray-600 mb-4 text-left">
                  Understand population shifts, demographic patterns, and community characteristics to identify emerging opportunities.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Age distribution mapping
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Income level analysis
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Migration pattern tracking
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature Card 3 */}
            <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.2s' }}>
              <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                <i className="fas fa-balance-scale text-purple-600 text-xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">Supply & Demand Forecasting</h3>
                <p className="text-gray-600 mb-4 text-left">
                  Predict future market conditions with advanced models that analyze supply and demand dynamics.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Construction pipeline analysis
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Absorption rate prediction
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Market equilibrium forecasting
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature Card 4 */}
            <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.3s' }}>
              <div className="w-12 h-12 bg-teal-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                <i className="fas fa-map-marker-alt text-teal-600 text-xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">Location Performance Metrics</h3>
                <p className="text-gray-600 mb-4 text-left">
                  Analyze market performance by specific locations to identify high-potential investment areas.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Neighborhood appreciation rates
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Proximity value analysis
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Geo-spatial performance mapping
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature Card 5 */}
            <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.4s' }}>
              <div className="w-12 h-12 bg-red-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                <i className="fas fa-building text-red-600 text-xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">Competitor Project Tracking</h3>
                <p className="text-gray-600 mb-4 text-left">
                  Monitor competing developments and projects to identify opportunities and threats in your target markets.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Development pipeline monitoring
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Competitive feature analysis
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Market saturation assessment
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature Card 6 */}
            <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.5s' }}>
              <div className="w-12 h-12 bg-amber-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                <i className="fas fa-layer-group text-amber-600 text-xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">Land Value Assessment</h3>
                <p className="text-gray-600 mb-4 text-left">
                  Evaluate land values based on multiple factors including location, zoning, and development potential.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Multi-factor valuation models
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Highest and best use analysis
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Development potential scoring
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="mt-16 text-center">
            <div className="inline-block bg-blue-50 px-6 py-4 rounded-lg border border-blue-100">
              <p className="text-blue-800 font-medium flex items-center">
                <i className="fas fa-info-circle mr-2 text-blue-500"></i>
                Our market intelligence data is updated in real-time, pulling from over 200 data sources
              </p>
            </div>
          </div>
        </div>
      </section>
      
      {/* Property Valuation Section */}
      <section ref={propertyRef} id="property-valuation" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Property Valuation
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Get accurate property valuations with our advanced AI-powered valuation models
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/* Main Feature Highlight */}
            <div className="relative rounded-xl overflow-hidden fade-in" style={{ minHeight: '500px' }}>
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-indigo-600 opacity-90"></div>
              <img src="/f3.png" alt="Property Valuation" className="relative z-10 w-full object-cover"/>
              <div className="relative p-10 text-white h-full flex flex-col justify-between text-left">
                <div>
                  <h3 className="text-2xl font-bold mb-4">Automated Valuation Models</h3>
                  <p className="text-lg mb-6">
                    Our AI-powered valuation engine analyzes thousands of data points to deliver accurate property valuations in minutes.
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <i className="fas fa-check-circle mt-1 mr-3 text-green-300"></i>
                      <span>Combines machine learning with traditional valuation methods</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle mt-1 mr-3 text-green-300"></i>
                      <span>Accounts for property-specific features and local market conditions</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle mt-1 mr-3 text-green-300"></i>
                      <span>Continuously improves accuracy through feedback loops</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle mt-1 mr-3 text-green-300"></i>
                      <span>Provides confidence scores with each valuation</span>
                    </li>
                  </ul>
                </div>
                <div className="mt-8">
                  <div className="inline-block bg-white bg-opacity-20 px-6 py-3 rounded-lg">
                    <p className="text-white font-medium">
                      <i className="fas fa-bolt mr-2"></i>
                      Valuations in under 2 minutes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Feature Cards */}
            <div className="space-y-6 fade-in" style={{ transitionDelay: '0.1s' }}>
              {/* Feature Card 1 */}
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
                <div className="flex items-start">
                  <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                    <i className="fas fa-percentage text-green-600 text-xl"></i>
                  </div>
                  <div className="text-left">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">Rental Yield Analysis</h3>
                    <p className="text-gray-600 mb-3">
                      Calculate expected rental returns across properties with sophisticated yield analysis tools.
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Gross yield</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Net yield</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Cash-on-cash return</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Feature Card 2 */}
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
                <div className="flex items-start">
                  <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                    <i className="fas fa-calculator text-blue-600 text-xl"></i>
                  </div>
                  <div className="text-left">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">ROI Calculator</h3>
                    <p className="text-gray-600 mb-3">
                      Determine investment returns with sophisticated financial models that account for all variables.
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">IRR</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">NPV</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Equity multiple</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Payback period</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Feature Card 3 */}
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
                <div className="flex items-start">
                  <div className="w-12 h-12 bg-amber-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                    <i className="fas fa-hard-hat text-amber-600 text-xl"></i>
                  </div>
                  <div className="text-left">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">Construction Cost Estimation</h3>
                    <p className="text-gray-600 mb-3">
                      Estimate development costs with precision using our comprehensive cost database and AI analysis.
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Material costs</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Labor costs</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Soft costs</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Feature Card 4 */}
              <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
                <div className="flex items-start">
                  <div className="w-12 h-12 bg-red-100 rounded-lg flex items-center justify-center mr-4 flex-shrink-0">
                    <i className="fas fa-chart-line text-red-600 text-xl"></i>
                  </div>
                  <div className="text-left">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">Price Trend Prediction</h3>
                    <p className="text-gray-600 mb-3">
                      Forecast future property values with AI-powered predictive models based on historical data and market factors.
                    </p>
                    <div className="flex flex-wrap gap-2">
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Short-term forecast</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Long-term projection</span>
                      <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Scenario analysis</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Additional Feature */}
          <div className="mt-16">
            <div className="bg-white rounded-xl shadow-lg overflow-hidden fade-in" style={{ transitionDelay: '0.2s' }}>
              <div className="grid grid-cols-1 md:grid-cols-3">
                <div className="md:col-span-1 bg-indigo-700 p-8 text-white text-left">
                  <h3 className="text-2xl font-bold mb-4">Comparative Market Analysis</h3>
                  <p className="text-lg mb-6">
                    Compare properties against relevant market benchmarks with our powerful CMA tools.
                  </p>
                  <div className="inline-block bg-indigo-600 px-4 py-2 rounded">
                    <p className="text-white font-medium text-sm">
                      <i className="fas fa-search-plus mr-2"></i>
                      Identify the most relevant comparables
                    </p>
                  </div>
                </div>
                <div className="md:col-span-2 p-8">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="flex items-start">
                      <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3 flex-shrink-0">
                        <i className="fas fa-bullseye text-blue-600"></i>
                      </div>
                      <div className="text-left">
                        <h4 className="font-semibold mb-1 text-gray-900">Precision Matching</h4>
                        <p className="text-gray-600">Identifies truly comparable properties using AI-driven similarity analysis</p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mr-3 flex-shrink-0">
                        <i className="fas fa-sliders-h text-green-600"></i>
                      </div>
                      <div className="text-left">
                        <h4 className="font-semibold mb-1 text-gray-900">Adjustable Parameters</h4>
                        <p className="text-gray-600">Customize comparison criteria based on your specific requirements</p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-3 flex-shrink-0">
                        <i className="fas fa-chart-pie text-purple-600"></i>
                      </div>
                      <div className="text-left">
                        <h4 className="font-semibold mb-1 text-gray-900">Visual Reports</h4>
                        <p className="text-gray-600">Generate intuitive visualizations of comparative property data</p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="w-10 h-10 bg-amber-100 rounded-full flex items-center justify-center mr-3 flex-shrink-0">
                        <i className="fas fa-history text-amber-600"></i>
                      </div>
                      <div className="text-left">
                        <h4 className="font-semibold mb-1 text-gray-900">Historical Context</h4>
                        <p className="text-gray-600">Analyze historical performance relative to market comparables</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Development Planning Section */}
      <section ref={developmentRef} id="development-planning" className="py-20">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Development Planning
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Comprehensive tools to evaluate development potential and optimize project planning
            </p>
          </div>
          
          {/* Feature Cards in Hexagon Grid Layout */}
          <div className="mb-20">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Card 1 */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-blue-500 fade-in">
                <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mx-auto mb-6">
                  <i className="fas fa-search-location text-blue-600 text-2xl"></i>
                </div>
                <div className="text-left">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Site Feasibility Analysis</h3>
                  <p className="text-gray-600 mb-5 text-left">
                    Evaluate development potential with comprehensive metrics that analyze physical, legal, and financial factors.
                  </p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Multi-factor site scoring system</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Physical constraints analysis</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Financial feasibility modeling</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              {/* Card 2 */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-purple-500 fade-in" style={{ transitionDelay: '0.1s' }}>
                <div className="w-16 h-16 rounded-full bg-purple-100 flex items-center justify-center mx-auto mb-6">
                  <i className="fas fa-clipboard-check text-purple-600 text-2xl"></i>
                </div>
                <div className="text-left">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Zoning & Regulation Compliance</h3>
                  <p className="text-gray-600 mb-5 text-left">
                    Ensure projects meet regulatory requirements with our comprehensive compliance checking tools.
                  </p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Up-to-date zoning database</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Automated compliance verification</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Regulatory change alerts</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              {/* Card 3 */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-green-500 fade-in" style={{ transitionDelay: '0.2s' }}>
                <div className="w-16 h-16 rounded-full bg-green-100 flex items-center justify-center mx-auto mb-6">
                  <i className="fas fa-chart-area text-green-600 text-2xl"></i>
                </div>
                <div className="text-left">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Development Potential Calculator</h3>
                  <p className="text-gray-600 mb-5 text-left">
                    Quantify the potential value of development opportunities with our advanced analytical tools.
                  </p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Highest and best use analysis</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Maximum development capacity</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Value-add opportunity identification</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              {/* Card 4 */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-red-500 fade-in" style={{ transitionDelay: '0.3s' }}>
                <div className="w-16 h-16 rounded-full bg-red-100 flex items-center justify-center mx-auto mb-6">
                  <i className="fas fa-road text-red-600 text-2xl"></i>
                </div>
                <div className="text-left">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Infrastructure Impact Assessment</h3>
                  <p className="text-gray-600 mb-5 text-left">
                    Understand how infrastructure affects property values and development potential.
                  </p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Transit accessibility analysis</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Utility capacity evaluation</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Future infrastructure impact modeling</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              {/* Card 5 */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-amber-500 fade-in" style={{ transitionDelay: '0.4s' }}>
                <div className="w-16 h-16 rounded-full bg-amber-100 flex items-center justify-center mx-auto mb-6">
                  <i className="fas fa-leaf text-amber-600 text-2xl"></i>
                </div>
                <div className="text-left">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Environmental Risk Analysis</h3>
                  <p className="text-gray-600 mb-5 text-left">
                    Identify and quantify environmental risk factors that could impact development.
                  </p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Climate risk assessment</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Contamination probability modeling</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Sustainability opportunity identification</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              {/* Card 6 */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border-t-4 border-teal-500 fade-in" style={{ transitionDelay: '0.5s' }}>
                <div className="w-16 h-16 rounded-full bg-teal-100 flex items-center justify-center mx-auto mb-6">
                  <i className="fas fa-clock text-teal-600 text-2xl"></i>
                </div>
                <div className="text-left">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900">Project Timeline Optimization</h3>
                  <p className="text-gray-600 mb-5 text-left">
                    Optimize development schedules for maximum efficiency and reduced carrying costs.
                  </p>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Critical path analysis</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Resource allocation optimization</span>
                    </li>
                    <li className="flex items-start">
                      <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                      <span>Risk-adjusted scheduling</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Investment Analytics Section */}
      <section ref={investmentRef} id="investment-analytics" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Investment Analytics
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Make data-driven investment decisions with our advanced analytics and portfolio management tools
            </p>
          </div>
          
          {/* Main Feature Highlight */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-16 fade-in">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="p-10 lg:p-16 flex flex-col justify-center">
                <div className="inline-block bg-blue-100 px-4 py-2 rounded-full text-blue-800 font-medium text-sm mb-6">
                  FEATURED
                </div>
                <h3 className="text-3xl font-bold text-gray-900 mb-6">Portfolio Optimization Engine</h3>
                <p className="text-lg text-gray-600 mb-8">
                  Optimize your real estate portfolio with our advanced analytics that consider risk, return, diversification, and market conditions to maximize performance.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                  <div className="flex items-start">
                    <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center mr-4 flex-shrink-0">
                      <i className="fas fa-balance-scale text-green-600 text-xl"></i>
                    </div>
                    <div className="text-left">
                      <h4 className="font-semibold mb-1">Risk-Return Balancing</h4>
                      <p className="text-gray-600 text-sm">Optimize risk-adjusted returns across your entire portfolio</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-12 h-12 rounded-full bg-purple-100 flex items-center justify-center mr-4 flex-shrink-0">
                      <i className="fas fa-chart-pie text-purple-600 text-xl"></i>
                    </div>
                    <div className="text-left">
                      <h4 className="font-semibold mb-1">Diversification Analysis</h4>
                      <p className="text-gray-600 text-sm">Ensure proper diversification across property types and locations</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-12 h-12 rounded-full bg-amber-100 flex items-center justify-center mr-4 flex-shrink-0">
                      <i className="fas fa-tasks text-amber-600 text-xl"></i>
                    </div>
                    <div className="text-left">
                      <h4 className="font-semibold mb-1">Rebalancing Recommendations</h4>
                      <p className="text-gray-600 text-sm">Get actionable insights on portfolio adjustments</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center mr-4 flex-shrink-0">
                      <i className="fas fa-bolt text-red-600 text-xl"></i>
                    </div>
                    <div className="text-left">
                      <h4 className="font-semibold mb-1">Market Timing Signals</h4>
                      <p className="text-gray-600 text-sm">Data-driven buy/sell signals based on market conditions</p>
                    </div>
                  </div>
                </div>
                <Link 
                  to="/contact"
                  className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors duration-300"
                >
                  <span>Optimize Your Portfolio</span>
                  <i className="fas fa-arrow-right ml-2"></i>
                </Link>
              </div>
              <div className="bg-gradient-to-br from-blue-500 to-indigo-700 p-10 flex items-center justify-center">
                <img src="/f2.png" alt="Portfolio Analytics" className="w-full h-auto max-w-md rounded-lg shadow-lg object-cover mx-auto" />
              </div>
            </div>
          </div>
          
          {/* Feature Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            {/* Feature Card 1 */}
            <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 fade-in">
              <div className="w-16 h-16 rounded-full bg-indigo-100 flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-chart-bar text-indigo-600 text-2xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-4 text-gray-900">Investment Performance Tracking</h3>
                <p className="text-gray-600 mb-5 text-left">
                  Track and analyze the performance of your real estate investments with comprehensive metrics and dashboards.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Real-time performance monitoring</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Customizable metrics and KPIs</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Performance benchmarking</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Historical trend analysis</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature Card 2 */}
            <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.1s' }}>
              <div className="w-16 h-16 rounded-full bg-green-100 flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-funnel-dollar text-green-600 text-2xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-4 text-gray-900">Cash Flow Analysis</h3>
                <p className="text-gray-600 mb-5 text-left">
                  Project and track cash flows with sophisticated modeling tools that account for all revenue and expense variables.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Detailed income and expense tracking</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Cash-on-cash return calculation</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Multi-year cash flow projections</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Sensitivity analysis tools</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature Card 3 */}
            <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.2s' }}>
              <div className="w-16 h-16 rounded-full bg-red-100 flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-shield-alt text-red-600 text-2xl"></i>
              </div>
              <div className="text-left">
                <h3 className="text-xl font-semibold mb-4 text-gray-900">Risk Assessment Tools</h3>
                <p className="text-gray-600 mb-5 text-left">
                  Identify, quantify, and mitigate risks across your real estate portfolio with advanced risk analysis tools.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Market risk evaluation</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Property-specific risk factors</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Downside scenario modeling</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Risk mitigation recommendations</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          {/* Additional Features in Tabs */}
          <div className="bg-white rounded-xl shadow-lg overflow-hidden fade-in" style={{ transitionDelay: '0.3s' }}>
            <div className="grid grid-cols-1 lg:grid-cols-5">
              <div className="lg:col-span-2 bg-gradient-to-br from-indigo-600 to-purple-700 p-10 text-left">
                <h3 className="text-2xl font-bold text-white mb-6">Advanced Investment Tools</h3>
                <p className="text-lg text-white opacity-90 mb-8">
                  Our platform includes specialized tools for sophisticated investors and fund managers to optimize decision-making.
                </p>
                <ul className="space-y-4 text-white">
                  <li className="flex items-center">
                    <i className="fas fa-check-circle mr-3 text-green-300"></i>
                    <span>Scenario Planning & Sensitivity Analysis</span>
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check-circle mr-3 text-green-300"></i>
                    <span>Exit Strategy Optimization</span>
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check-circle mr-3 text-green-300"></i>
                    <span>Tax Impact Modeling</span>
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check-circle mr-3 text-green-300"></i>
                    <span>Financing Structure Analysis</span>
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-check-circle mr-3 text-green-300"></i>
                    <span>Investment Opportunity Scoring</span>
                  </li>
                </ul>
              </div>
              <div className="lg:col-span-3 p-8 text-left">
                <div className="mb-8">
                  <div className="flex items-center justify-between border-b border-gray-200">
                    <h4 className="text-xl font-semibold text-gray-900 py-4">Deal Analyzer</h4>
                    <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">Popular</span>
                  </div>
                  <p className="mt-4 text-gray-600">
                    Comprehensive tool for analyzing potential property acquisitions with detailed financial modeling, risk assessment, and return projections. Supports multiple property types and investment strategies.
                  </p>
                </div>
                <div className="mb-8">
                  <div className="flex items-center justify-between border-b border-gray-200">
                    <h4 className="text-xl font-semibold text-gray-900 py-4">Fund Manager Dashboard</h4>
                    <span className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm font-medium">Enterprise</span>
                  </div>
                  <p className="mt-4 text-gray-600">
                    Purpose-built analytics for fund managers to track performance across multiple properties, manage investor reporting, and optimize allocation decisions for maximum risk-adjusted returns.
                  </p>
                </div>
                <div>
                  <div className="flex items-center justify-between border-b border-gray-200">
                    <h4 className="text-xl font-semibold text-gray-900 py-4">Opportunity Finder</h4>
                    <span className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-medium">AI-Powered</span>
                  </div>
                  <p className="mt-4 text-gray-600">
                    AI-driven tool that identifies potential investment opportunities based on your criteria, market conditions, and predictive analytics. Highlights properties with the highest potential for appreciation and cash flow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* AI Capabilities Section */}
      <section ref={aiRef} id="ai-capabilities" className="py-20">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              AI-Powered Features
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Experience the power of artificial intelligence and machine learning to unlock deeper insights and automate complex real estate analysis
            </p>
          </div>
          
          {/* Hero Feature */}
          <div className="relative rounded-3xl overflow-hidden mb-20 fade-in">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-900 to-indigo-900"></div>
            <div className="absolute inset-0 opacity-20">
              <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
                <defs>
                  <radialGradient id="rgrad" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stopColor="#fff" stopOpacity="0.3" />
                    <stop offset="100%" stopColor="#fff" stopOpacity="0" />
                  </radialGradient>
                </defs>
                <rect x="0" y="0" width="100" height="100" fill="url(#rgrad)" />
                <g fill="none" stroke="white" strokeWidth="0.3">
                  <circle cx="20" cy="20" r="2" />
                  <circle cx="40" cy="80" r="3" />
                  <circle cx="65" cy="40" r="1.5" />
                  <circle cx="75" cy="75" r="2.5" />
                  <circle cx="85" cy="15" r="1" />
                  <circle cx="15" cy="60" r="1.5" />
                  <circle cx="30" cy="35" r="2" />
                  <circle cx="90" cy="50" r="1" />
                </g>
              </svg>
            </div>
            <div className="relative z-10 grid grid-cols-1 lg:grid-cols-2 p-16">
              <div className="text-white">
                <h3 className="text-3xl font-bold mb-6">Predictive Analytics Engine</h3>
                <p className="text-xl opacity-90 mb-10">
                  Our advanced AI predictive engine processes millions of data points to forecast market trends, property values, and investment returns with unprecedented accuracy.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
                  <div>
                    <h4 className="text-xl font-semibold mb-4 flex items-center">
                      <i className="fas fa-brain text-purple-300 mr-3"></i>
                      <span>Machine Learning Models</span>
                    </h4>
                    <p className="opacity-80">
                      Continuously learning and improving from market data and outcomes to enhance prediction accuracy over time
                    </p>
                  </div>
                  <div>
                    <h4 className="text-xl font-semibold mb-4 flex items-center">
                      <i className="fas fa-chart-line text-purple-300 mr-3"></i>
                      <span>Trend Forecasting</span>
                    </h4>
                    <p className="opacity-80">
                      Identify emerging market trends before they become obvious, giving you a critical competitive advantage
                    </p>
                  </div>
                  <div>
                    <h4 className="text-xl font-semibold mb-4 flex items-center">
                      <i className="fas fa-bullseye text-purple-300 mr-3"></i>
                      <span>Precision Targeting</span>
                    </h4>
                    <p className="opacity-80">
                      Pinpoint exactly which properties and areas match your investment criteria with uncanny precision
                    </p>
                  </div>
                  <div>
                    <h4 className="text-xl font-semibold mb-4 flex items-center">
                      <i className="fas fa-magic text-purple-300 mr-3"></i>
                      <span>Scenario Simulation</span>
                    </h4>
                    <p className="opacity-80">
                      Run thousands of possible future scenarios to stress-test investment decisions and optimize outcomes
                    </p>
                  </div>
                </div>
                <div className="inline-block bg-white bg-opacity-20 px-6 py-3 rounded-lg">
                  <p className="text-white font-medium flex items-center">
                    <i className="fas fa-bolt mr-2"></i>
                    <span>Our AI models achieve 92% accuracy in 6-month price forecasts</span>
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center lg:justify-end mt-12 lg:mt-0">
                <img src="/f1.png" alt="AI Analytics" className="max-w-full lg:max-w-md rounded-xl shadow-2xl object-cover mx-auto" />
              </div>
            </div>
          </div>
          
          {/* AI Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
            {/* Feature 1 */}
            <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden fade-in">
              <div className="h-48 bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center p-6">
                <i className="fas fa-robot text-white text-5xl"></i>
              </div>
              <div className="p-8">
                <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Natural Language Search</h3>
                <p className="text-gray-600 mb-6 text-center">
                  Ask complex real estate questions in plain English and get instant, data-backed answers without needing to master complex filters.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Conversational property search</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Data extraction from unstructured text</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Context-aware follow-up questions</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature 2 */}
            <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden fade-in" style={{ transitionDelay: '0.1s' }}>
              <div className="h-48 bg-gradient-to-r from-purple-500 to-pink-600 flex items-center justify-center p-6">
                <i className="fas fa-image text-white text-5xl"></i>
              </div>
              <div className="p-8">
                <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Computer Vision Analysis</h3>
                <p className="text-gray-600 mb-6 text-center">
                  Extract detailed property features from images and videos, automatically identifying conditions, amenities, and potential issues.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Automated property feature detection</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Condition assessment from images</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Renovation potential identification</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature 3 */}
            <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden fade-in" style={{ transitionDelay: '0.2s' }}>
              <div className="h-48 bg-gradient-to-r from-amber-500 to-red-600 flex items-center justify-center p-6">
                <i className="fas fa-cogs text-white text-5xl"></i>
              </div>
              <div className="p-8">
                <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Automated Due Diligence</h3>
                <p className="text-gray-600 mb-6 text-center">
                  Let AI analyze property documents, contracts, and legal filings to identify risks and opportunities that human analysis might miss.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Document analysis and extraction</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Risk factor identification</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Title and permit verification</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature 4 */}
            <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden fade-in" style={{ transitionDelay: '0.3s' }}>
              <div className="h-48 bg-gradient-to-r from-green-500 to-teal-600 flex items-center justify-center p-6">
                <i className="fas fa-city text-white text-5xl"></i>
              </div>
              <div className="p-8">
                <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Urban Development Modeling</h3>
                <p className="text-gray-600 mb-6 text-center">
                  Simulate how urban development patterns will evolve over time, predicting future hotspots and investment opportunities.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Growth pattern prediction</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Gentrification early warning</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Infrastructure impact modeling</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature 5 */}
            <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden fade-in" style={{ transitionDelay: '0.4s' }}>
              <div className="h-48 bg-gradient-to-r from-blue-600 to-cyan-600 flex items-center justify-center p-6">
                <i className="fas fa-comments text-white text-5xl"></i>
              </div>
              <div className="p-8">
                <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Sentiment Analysis</h3>
                <p className="text-gray-600 mb-6 text-center">
                  Track and analyze market sentiment from news, social media, and other sources to identify shifting market trends before they appear in the data.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Real-time sentiment tracking</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>News and social media analysis</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Early market shift detection</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Feature 6 */}
            <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden fade-in" style={{ transitionDelay: '0.5s' }}>
              <div className="h-48 bg-gradient-to-r from-indigo-700 to-violet-800 flex items-center justify-center p-6">
                <i className="fas fa-lightbulb text-white text-5xl"></i>
              </div>
              <div className="p-8">
                <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Intelligent Recommendations</h3>
                <p className="text-gray-600 mb-6 text-center">
                  Receive personalized property and investment recommendations that learn from your preferences and previous decisions.
                </p>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Personalized property matching</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Investment strategy alignment</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-2"></i>
                    <span>Continuous preference learning</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          {/* AI Technology Platform */}
          <div className="bg-gray-100 rounded-2xl overflow-hidden p-10 mb-12 fade-in" style={{ transitionDelay: '0.6s' }}>
            <div className="text-center mb-12">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Our AI Technology Platform</h3>
              <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                Built on cutting-edge AI technologies that work together to provide unprecedented insights and automation
              </p>
            </div>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 text-center">
              <div className="bg-white p-6 rounded-xl shadow">
                <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <i className="fas fa-brain text-indigo-600 text-2xl"></i>
                </div>
                <h4 className="font-semibold mb-2 text-center">Deep Learning</h4>
                <p className="text-sm text-gray-600 text-center">Neural networks that identify complex patterns in real estate data</p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow">
                <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <i className="fas fa-language text-green-600 text-2xl"></i>
                </div>
                <h4 className="font-semibold mb-2 text-center">NLP</h4>
                <p className="text-sm text-gray-600 text-center">Process and understand natural language queries and documents</p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow">
                <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <i className="fas fa-project-diagram text-blue-600 text-2xl"></i>
                </div>
                <h4 className="font-semibold mb-2 text-center">Graph Analysis</h4>
                <p className="text-sm text-gray-600 text-center">Map relationships between properties, markets, and economic factors</p>
              </div>
              
              <div className="bg-white p-6 rounded-xl shadow">
                <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <i className="fas fa-eye text-purple-600 text-2xl"></i>
                </div>
                <h4 className="font-semibold mb-2 text-center">Computer Vision</h4>
                <p className="text-sm text-gray-600 text-center">Extract valuable data from images, videos, and visual content</p>
              </div>
            </div>
          </div>
          
        </div>
      </section>
      
      {/* Call to Action */}
      <section className="py-20 bg-gray-900 text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 fade-in">
            Ready to Explore Winpra?
          </h2>
          <p className="text-xl mb-10 max-w-3xl mx-auto fade-in" style={{ transitionDelay: '0.1s' }}>
            Get in touch with our team to schedule a personalized demo and see how Winpra can transform your real estate decisions.
          </p>
          <Link 
            to="/contact" 
            className="inline-block px-8 py-4 bg-primary hover:bg-primary-dark rounded-lg font-semibold transition-all duration-300 fade-in transform hover:scale-105"
            style={{ transitionDelay: '0.2s' }}
          >
            Request a Demo
          </Link>
        </div>
      </section>
      
      {/* Add CSS for fade-in animations */}
      <style jsx="true">{`
        .fade-in {
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        }
        .fade-in.visible {
          opacity: 1;
          transform: translateY(0);
        }
      `}</style>
    </>
  );
};

export default Features; 