import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  // Animation observer for fade-in effects
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('appear');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.footer-fade-in').forEach(element => {
      observer.observe(element);
    });

    return () => {
      document.querySelectorAll('.footer-fade-in').forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);
  
  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    const emailInput = document.getElementById('subscribe-email');
    const subscribeSuccess = document.getElementById('subscribe-success');
    
    if (emailInput && emailInput.value && subscribeSuccess) {
      // Show success message
      subscribeSuccess.classList.remove('hidden');
      
      // Reset form
      emailInput.value = '';
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        subscribeSuccess.classList.add('hidden');
      }, 5000);
    }
  };

  return (
    <footer className="bg-gradient-to-b from-gray-900 to-gray-950 text-white pt-20 pb-10 relative">
      {/* Top wave divider */}
      <div className="absolute top-0 left-0 right-0 transform -translate-y-99.5%">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="w-full">
          <path fill="#111827" fillOpacity="1" d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,176C672,160,768,128,864,128C960,128,1056,160,1152,176C1248,192,1344,192,1392,192L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </div>
      
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo and Company Info */}
          <div className="footer-fade-in" style={{ transitionDelay: '0.1s' }}>
            <Link to="/" className="inline-block mb-6 hover:opacity-80 transition-opacity">
              <img src={Logo} alt="Winpra Logo" className="h-12" />
            </Link>
            <h3 className="text-2xl font-bold mb-4 text-gradient">Winpra</h3>
            <p className="text-gray-400 mb-6">
              Revolutionizing real estate with AI-driven insights.
            </p>
          </div>
          
          {/* Quick Links */}
          <div className="footer-fade-in" style={{ transitionDelay: '0.2s' }}>
            <h4 className="text-lg font-semibold mb-6 text-white text-left">Quick Links</h4>
            <ul className="space-y-3">
              <li>
                <a href="#what-is-winpra" className="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center">
                  <i className="fas fa-chevron-right text-xs mr-2 text-primary"></i>
                  <span>About</span>
                </a>
              </li>
              <li>
                <a href="#how-it-works" className="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center">
                  <i className="fas fa-chevron-right text-xs mr-2 text-primary"></i>
                  <span>How It Works</span>
                </a>
              </li>
              <li>
                <a href="#features" className="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center">
                  <i className="fas fa-chevron-right text-xs mr-2 text-primary"></i>
                  <span>Features</span>
                </a>
              </li>
              <li>
                <a href="#use-cases" className="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center">
                  <i className="fas fa-chevron-right text-xs mr-2 text-primary"></i>
                  <span>Use Cases</span>
                </a>
              </li>
            </ul>
          </div>
          
          {/* Legal */}
          <div className="footer-fade-in" style={{ transitionDelay: '0.3s' }}>
            <h4 className="text-lg font-semibold mb-6 text-white text-left">Legal</h4>
            <ul className="space-y-3">
              <li>
                <Link to="/terms" className="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center">
                  <i className="fas fa-chevron-right text-xs mr-2 text-primary"></i>
                  <span>Terms of Service</span>
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center">
                  <i className="fas fa-chevron-right text-xs mr-2 text-primary"></i>
                  <span>Privacy Policy</span>
                </Link>
              </li>
              <li>
                <Link to="/cookies" className="text-gray-400 hover:text-primary transition-colors duration-300 flex items-center">
                  <i className="fas fa-chevron-right text-xs mr-2 text-primary"></i>
                  <span>Cookie Policy</span>
                </Link>
              </li>
            </ul>
          </div>
          
          {/* Stay Updated */}
          <div className="footer-fade-in" style={{ transitionDelay: '0.4s' }}>
            <h4 className="text-lg font-semibold mb-6 text-white">Stay Updated</h4>
            <p className="text-gray-400 mb-4">Subscribe to our newsletter for the latest updates and insights.</p>
            <form onSubmit={handleNewsletterSubmit} className="flex flex-col sm:flex-row">
              <div className="relative flex-grow">
                <i className="fas fa-envelope absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
                <input 
                  type="email" 
                  id="subscribe-email" 
                  placeholder="Enter your email" 
                  className="bg-gray-800 text-white pl-10 pr-4 py-3 rounded-l w-full focus:outline-none focus:ring-2 focus:ring-primary focus:bg-gray-700 transition-all duration-300" 
                  required
                />
              </div>
              <button 
                type="submit" 
                className="bg-primary hover:bg-primary-dark text-white font-medium py-3 px-5 rounded-r transition-colors duration-300 flex items-center justify-center"
              >
                <span>Subscribe</span>
                <i className="fas fa-paper-plane ml-2"></i>
              </button>
            </form>
            <div id="subscribe-success" className="hidden mt-3 p-2 bg-green-900/20 border border-green-600/30 text-green-400 rounded text-sm">
              <i className="fas fa-check-circle mr-2"></i>Thank you for subscribing!
            </div>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-16 pt-8 text-center">
          <p className="text-gray-400">
            © {currentYear} Winpra. All rights reserved.
          </p>
        </div>
      </div>

      <style jsx="true">{`
        .footer-fade-in {
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        }
        .footer-fade-in.appear {
          opacity: 1;
          transform: translateY(0);
        }
        .text-gradient {
          background: linear-gradient(to right, #3b82f6, #2563eb);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      `}</style>
    </footer>
  );
};

export default Footer; 