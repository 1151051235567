import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { submitDemoRequest, getMarketTrends, getPropertyGrowth, getDemographicData, getMarketStats, getAvailableLocations, getPriceStatistics, getPropertyStatistics, getRoomStatistics, getYearStatistics } from '../api/api';
import Charts from '../components/Charts';
import { Helmet } from 'react-helmet-async';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  PieChart, Pie, Cell, LineChart, Line, Legend
} from 'recharts';
import { FadeLoader } from 'react-spinners';

// COLORS
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#FF6666'];

// Create chart wrapper components to prevent hook errors
const PriceDistributionChart = ({ data }) => {
  if (!data || data.length === 0) return <div>No data available</div>;
  
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={true}
          label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `${value}%`} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const BarChartWrapper = ({ data, dataKey, xAxisKey, fill, formatter, labelFormatter }) => {
  if (!data || data.length === 0) return <div>No data available</div>;
  
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis tickFormatter={formatter} />
        <Tooltip formatter={formatter} labelFormatter={labelFormatter} />
        <Bar dataKey={dataKey} name="Percentage" fill={fill}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    company: '',
    role: ''
  });
  const [formStatus, setFormStatus] = useState({
    message: '',
    isError: false,
    isSubmitting: false
  });
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [activeTab, setActiveTab] = useState('prices');
  const [selectedLocation, setSelectedLocation] = useState('uae');
  const [availableLocations, setAvailableLocations] = useState([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [priceStats, setPriceStats] = useState(null);
  const [propertyStats, setPropertyStats] = useState(null);
  const [roomStats, setRoomStats] = useState(null);
  const [yearStats, setYearStats] = useState(null);
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  
  // Import all chart components from Charts
  const { 
    HeroChart, 
    MarketTrendsChart,
    PortfolioPerformanceChart,
    GrowthAnalysisChart,
    DemographicsCharts
  } = Charts();

  // Data state
  const [marketData, setMarketData] = useState({
    trends: [],
    growth: [],
    demographics: {
      age: [],
      income: []
    },
    stats: {
      averagePrice: 0,
      inventory: 0,
      daysOnMarket: 0
    },
    loading: true,
    error: null
  });

  // Fetch available locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setIsLoadingLocations(true);
        const locations = await getAvailableLocations();
        setAvailableLocations(locations);
        
        // Set default location to UAE
        if (locations && locations.length > 0) {
          // Look for UAE in the locations array
          const uaeLocation = locations.find(loc => 
            loc.toLowerCase() === 'uae' || 
            loc.toLowerCase() === 'dubai' || 
            loc.toLowerCase().includes('uae')
          );
          
          // Set to UAE if found, otherwise use the first location
          setSelectedLocation(uaeLocation ? uaeLocation.toLowerCase() : locations[0].toLowerCase());
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
      } finally {
        setIsLoadingLocations(false);
      }
    };

    fetchLocations();
  }, []);

  // Fetch market data
  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        // Skip if no location is selected
        if (!selectedLocation) return;
        
        const [trendsData, growthData, demographicData, statsData] = await Promise.all([
          getMarketTrends(selectedLocation),
          getPropertyGrowth(selectedLocation),
          getDemographicData(selectedLocation),
          getMarketStats(selectedLocation)
        ]);

        // Process and format the data
        const processedData = {
          trends: processTrendsData(trendsData),
          growth: processGrowthData(growthData),
          demographics: processDemographicData(demographicData),
          stats: processStatsData(statsData)
        };

        setMarketData(prev => ({
          ...prev,
          ...processedData,
          loading: false,
          error: null
        }));
      } catch (error) {
        console.error('Error fetching market data:', error);
        setMarketData(prev => ({
          ...prev,
          loading: false,
          error: 'Failed to fetch market data'
        }));
      }
    };

    setMarketData(prev => ({ ...prev, loading: true }));
    fetchMarketData();
  }, [selectedLocation]); // Reload when location changes

  // Data processing functions
  const processTrendsData = (data) => {
    // Process trends data
    if (data.properties && Array.isArray(data.properties)) {
      return data.properties.map(property => ({
        date: property.date || new Date(property.last_update || property.list_date || Date.now()).toLocaleDateString(),
        price: property.price || 350000
      }));
    }
    // Return empty array as fallback
    return [];
  };

  const processGrowthData = (data) => {
    // Handle the new response format
    if (data.growth && Array.isArray(data.growth)) {
      return data.growth;
    }
    
    // Handle old API response format if it exists
    if (data.properties && Array.isArray(data.properties)) {
      const propertyTypes = ['Residential', 'Commercial', 'Industrial', 'Mixed-Use'];
      return propertyTypes.map(type => ({
        type,
        growth: Math.random() * 10 + 2 // Simulated growth data
      }));
    }
    
    // Return fallback data
    return [
      { type: 'Residential', growth: 6.5 },
      { type: 'Commercial', growth: 4.2 },
      { type: 'Industrial', growth: 5.8 },
      { type: 'Mixed-Use', growth: 5.3 }
    ];
  };

  const processDemographicData = (data) => {
    // Handle the new response format, which should include age and income
    if (data && data.age && data.income) {
      return data;
    }
    
    // Process demographic data with fallback
    return {
      age: [
        { name: '18-24', value: 15 },
        { name: '25-34', value: 30 },
        { name: '35-44', value: 25 },
        { name: '45-54', value: 20 },
        { name: '55+', value: 10 }
      ],
      income: [
        { range: '$0-50k', value: 20 },
        { range: '$50k-100k', value: 35 },
        { range: '$100k-150k', value: 25 },
        { range: '$150k+', value: 20 }
      ]
    };
  };

  const processStatsData = (data) => {
    // Handle the market stats
    if (data && data.average_price && data.total_inventory && data.median_days_on_market) {
      return {
        averagePrice: data.average_price,
        inventory: data.total_inventory,
        daysOnMarket: data.median_days_on_market
      };
    }
    
    // Process market statistics with fallback
    return {
      averagePrice: 425000,
      inventory: 12450,
      daysOnMarket: 45
    };
  };

  // Fetch statistics when location changes
  useEffect(() => {
    if (selectedLocation) {
      fetchRealEstateStats();
    }
  }, [selectedLocation]);
  
  // Fetch all real estate statistics data
  const fetchRealEstateStats = async () => {
    setIsLoadingStats(true);
    try {
      const [priceData, propertyData, roomData, yearData] = await Promise.all([
        getPriceStatistics(selectedLocation),
        getPropertyStatistics(selectedLocation),
        getRoomStatistics(selectedLocation),
        getYearStatistics(selectedLocation)
      ]);
      
      setPriceStats(priceData);
      setPropertyStats(propertyData);
      setRoomStats(roomData);
      setYearStats(yearData);
    } catch (error) {
      console.error('Error fetching real estate statistics:', error);
    } finally {
      setIsLoadingStats(false);
    }
  };
  
  // Process price data for charts
  const processPriceData = () => {
    if (!priceStats) return null;
    
    // Format price ranges with percentages for pie chart
    const pieData = priceStats.priceRanges.map(item => ({
      name: item.range,
      value: item.percentage
    }));
    
    // Helper for formatting currency
    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(value);
    };
    
    return {
      pieData,
      averagePrice: formatCurrency(priceStats.averagePrice),
      medianPrice: formatCurrency(priceStats.medianPrice),
      minPrice: formatCurrency(priceStats.minPrice),
      maxPrice: formatCurrency(priceStats.maxPrice)
    };
  };
  
  // Process property statistics for charts
  const processPropertyData = () => {
    if (!propertyStats) return null;
    
    // Format area ranges for bar chart
    const areaData = propertyStats.areaRanges.map(item => ({
      name: item.range,
      value: item.percentage
    }));
    
    // Format price per square meter
    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(value);
    };
    
    return {
      areaData,
      averageArea: Math.round(propertyStats.averageArea),
      medianArea: Math.round(propertyStats.medianArea),
      minArea: Math.round(propertyStats.minArea),
      maxArea: Math.round(propertyStats.maxArea),
      pricePerSqm: formatCurrency(propertyStats.pricePerSqm)
    };
  };
  
  // Process room statistics for charts
  const processRoomData = () => {
    if (!roomStats) return null;
    
    return {
      bedroomData: roomStats.bedroomDistribution,
      bathroomData: roomStats.bathroomDistribution
    };
  };
  
  // Process year statistics for charts
  const processYearData = () => {
    if (!yearStats) return null;
    
    return {
      yearData: yearStats.yearDistribution,
      averageAge: Math.round(yearStats.averageAge)
    };
  };

  // Observer for fade-in elements
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('appear');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.fade-in').forEach(element => {
      observer.observe(element);
    });

    // Setup scroll event listener for back to top button
    const checkScrollTop = () => {
      if (window.pageYOffset > 300) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);

    // Parallax effect for hero section
    const parallaxElements = document.querySelectorAll('.parallax-bg');
    
    const handleParallax = () => {
      const scrolled = window.pageYOffset;
      parallaxElements.forEach(element => {
        const speed = element.dataset.speed || 0.1;
        element.style.transform = `translateY(${scrolled * speed}px)`;
      });
    };

    window.addEventListener('scroll', handleParallax);

    return () => {
      document.querySelectorAll('.fade-in').forEach(element => {
        observer.unobserve(element);
      });
      window.removeEventListener('scroll', checkScrollTop);
      window.removeEventListener('scroll', handleParallax);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus({
      message: '',
      isError: false,
      isSubmitting: true
    });

    try {
      await submitDemoRequest({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        company: formData.company,
        role: formData.role
      });
      
      setFormData({ name: '', email: '', message: '', company: '', role: '' });
      setFormStatus({
        message: 'Thank you for your interest! We\'ll be in touch soon.',
        isError: false,
        isSubmitting: false
      });
      
      // Clear success message after 5 seconds
      setTimeout(() => {
        setFormStatus({
          message: '',
          isError: false,
          isSubmitting: false
        });
      }, 5000);
      
    } catch (error) {
      console.error('Error:', error);
      setFormStatus({
        message: 'Something went wrong. Please try again later.',
        isError: true,
        isSubmitting: false
      });
    }
  };

  // Render price statistics tab
  const renderPriceStats = () => {
    const data = processPriceData();
    if (!data) return <div>No price data available</div>;

  return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Property Price Distribution</h3>
          <PriceDistributionChart data={data.pieData} />
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Price Statistics</h3>
          <div className="space-y-4">
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Average Price:</span>
              <span className="font-semibold text-blue-600">{data.averagePrice}</span>
            </div>
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Median Price:</span>
              <span className="font-semibold text-blue-600">{data.medianPrice}</span>
            </div>
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Minimum Price:</span>
              <span className="font-semibold text-blue-600">{data.minPrice}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Maximum Price:</span>
              <span className="font-semibold text-blue-600">{data.maxPrice}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Render property statistics tab
  const renderPropertyStats = () => {
    const data = processPropertyData();
    if (!data) return <div>No property data available</div>;
    
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Property Size Distribution</h3>
          <BarChartWrapper 
            data={data.areaData} 
            dataKey="value" 
            xAxisKey="name" 
            fill="#8884d8"
            formatter={(value) => `${value}%`}
          />
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Property Size Statistics</h3>
          <div className="space-y-4">
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Average Area:</span>
              <span className="font-semibold text-blue-600">{data.averageArea} m²</span>
            </div>
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Median Area:</span>
              <span className="font-semibold text-blue-600">{data.medianArea} m²</span>
            </div>
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Minimum Area:</span>
              <span className="font-semibold text-blue-600">{data.minArea} m²</span>
            </div>
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Maximum Area:</span>
              <span className="font-semibold text-blue-600">{data.maxArea} m²</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Avg. Price per m²:</span>
              <span className="font-semibold text-blue-600">{data.pricePerSqm}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Render room statistics tab
  const renderRoomStats = () => {
    const data = processRoomData();
    if (!data) return <div>No room data available</div>;
    
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Bedroom Distribution</h3>
          <BarChartWrapper 
            data={data.bedroomData} 
            dataKey="percentage" 
            xAxisKey="bedrooms" 
            fill="#0088FE"
            formatter={(value) => `${value}%`}
            labelFormatter={(value) => `${value} Bedroom(s)`}
          />
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Bathroom Distribution</h3>
          <BarChartWrapper 
            data={data.bathroomData} 
            dataKey="percentage" 
            xAxisKey="bathrooms" 
            fill="#00C49F"
            formatter={(value) => `${value}%`}
            labelFormatter={(value) => `${value} Bathroom(s)`}
          />
        </div>
      </div>
    );
  };
  
  // Render year statistics tab
  const renderYearStats = () => {
    const data = processYearData();
    if (!data) return <div>No construction year data available</div>;
    
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Construction Year Distribution</h3>
          <BarChartWrapper 
            data={data.yearData} 
            dataKey="percentage" 
            xAxisKey="period" 
            fill="#FFBB28"
            formatter={(value) => `${value}%`}
          />
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h3 className="text-xl font-semibold mb-6 text-gray-800">Property Age Statistics</h3>
          <div className="space-y-4">
            <div className="flex justify-between items-center border-b pb-2">
              <span className="text-gray-600">Average Property Age:</span>
              <span className="font-semibold text-blue-600">{data.averageAge} years</span>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg mt-4">
              <p className="text-gray-600">
                Property age distribution shows when most properties in {selectedLocation.toUpperCase()} were built. 
                Newer properties typically have modern amenities and design, while older properties might offer 
                character and potentially lower prices.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Render active tab content
  const renderActiveTabContent = () => {
    if (isLoadingStats) {
      return (
        <div className="flex justify-center items-center h-64">
          <FadeLoader color="#3B82F6" />
        </div>
      );
    }
    
    switch (activeTab) {
      case 'prices':
        return renderPriceStats();
      case 'properties':
        return renderPropertyStats();
      case 'rooms':
        return renderRoomStats();
      case 'years':
        return renderYearStats();
      default:
        return <div>Select a tab to view statistics</div>;
    }
  };

  return (
    <>
      <Helmet>
        <title>Winpra | Real Estate Analytics</title>
        <meta name="description" content="Advanced analytics for real estate investors and professionals" />
      </Helmet>
      
      <div className="min-h-screen bg-gray-50">
        {/* Hero Section with Enhanced Styling and Animations */}
        <section className="relative bg-gradient-to-r from-primary-dark to-primary-light text-white pt-32 pb-20 overflow-hidden">
          {/* Animated background elements */}
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <svg width="100%" height="100%" viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="smallGrid" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect width="20" height="20" fill="none" stroke="white" strokeWidth="0.5"/>
              </pattern>
              <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
                <rect width="80" height="80" fill="url(#smallGrid)"/>
                <rect width="80" height="80" fill="none" stroke="white" strokeWidth="1"/>
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        </div>
        
            {/* Animated floating blobs */}
            <div className="absolute -top-24 -left-24 w-96 h-96 bg-blue-400 opacity-20 rounded-full blur-3xl animate-blob parallax-bg" data-speed="0.05"></div>
            <div className="absolute top-1/3 -right-24 w-80 h-80 bg-purple-400 opacity-20 rounded-full blur-3xl animate-blob animation-delay-2000 parallax-bg" data-speed="0.1"></div>
            <div className="absolute -bottom-24 left-1/3 w-72 h-72 bg-indigo-400 opacity-20 rounded-full blur-3xl animate-blob animation-delay-4000 parallax-bg" data-speed="0.15"></div>
          </div>

          <div className="container mx-auto px-6 relative z-10">
            <div className="flex flex-col lg:flex-row items-center lg:space-x-10">
              <div className="lg:w-1/2 mb-12 lg:mb-0 fade-in text-left">
                <span className="inline-block py-1 px-3 rounded-full bg-white/20 text-sm font-medium mb-6 animate-pulse">
                  Next-Gen Real Estate Analytics Platform
                </span>
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight">
                  Transform Real Estate Decisions with <span className="text-blue-200">AI-Powered Analytics</span>
              </h1>
                <p className="text-xl mb-8 text-blue-100 max-w-lg">
                  Leverage advanced AI and machine learning to make data-driven decisions in property development, investment, and market analysis with unprecedented accuracy.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <a href="/contact" className="group bg-white text-primary-dark font-semibold px-8 py-3 rounded-lg hover:bg-gray-100 transition duration-300 shadow-lg flex items-center justify-center transform hover:-translate-y-1 hover:shadow-xl">
                    <i className="fas fa-rocket mr-2 group-hover:animate-bounce"></i>Request Demo
                  </a>
                  <a href="#features" className="group border-2 border-white/70 bg-primary-dark text-white font-semibold px-8 py-3 rounded-lg hover:bg-blue-800 transition duration-300 flex items-center justify-center transform hover:-translate-y-1">
                    <i className="fas fa-play-circle mr-2 group-hover:rotate-12 transition-transform"></i>Explore Features
                </a>
              </div>
            </div>
              <div className="lg:w-1/2 fade-in" style={{ transitionDelay: '0.3s' }}>
                <div className="bg-white p-6 rounded-xl shadow-2xl transform transition duration-500 hover:scale-[1.02] hover:shadow-blue-900/20">
                  <div className="h-[400px]">
                    <HeroChart />
                </div>
              </div>
            </div>
          </div>
        </div>
          
          {/* Wave divider at bottom */}
          <div className="absolute bottom-0 left-0 right-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="w-full">
              <path fill="#ffffff" fillOpacity="1" d="M0,224L48,213.3C96,203,192,181,288,181.3C384,181,480,203,576,224C672,245,768,267,864,250.7C960,235,1056,181,1152,165.3C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
          </div>
        </section>

        {/* Enhanced Market Insights Section */}
        <section id="market-insights" className="py-24 bg-white">
          <div className="container mx-auto px-6">
            <div className="text-center max-w-3xl mx-auto mb-12 fade-in">
              <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-blue-50">
                <span className="px-3 py-1 text-xs font-medium text-primary-dark">DATA VISUALIZATION</span>
              </div>
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900">Real Estate Market Insights</h2>
              <p className="text-lg text-gray-600">Visualize real estate trends with our interactive charts, providing valuable insights for informed investment decisions.</p>
            </div>
            
            {/* Tab Content */}
            <div className="relative bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 p-8 rounded-3xl shadow-lg">
              <div className="absolute inset-0 bg-white/40 backdrop-blur-sm rounded-3xl"></div>
              <div className="relative z-10">
                <div className="mb-8 text-center">
                  <h3 className="text-2xl font-bold text-gray-800 mb-2">Interactive Market Analytics</h3>
                  <p className="text-gray-600 mb-8">Explore real-time market data and trends through our interactive visualization tools</p>
                  
                  <div className="flex flex-wrap items-center justify-center gap-4">
                    {/* Location Selector with improved styling */}
                    <div className="flex items-center bg-white rounded-xl shadow-sm p-3 border border-gray-100">
                      <label htmlFor="location-select" className="text-sm font-bold text-gray-700 whitespace-nowrap mr-3">
                        Select Market Location:
                      </label>
                      <div className="relative">
                        <select
                          id="location-select"
                          value={selectedLocation}
                          onChange={(e) => setSelectedLocation(e.target.value)}
                          className="w-48 px-4 py-2.5 rounded-lg bg-gray-50 text-gray-700 font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-all duration-200 appearance-none"
                        >
                          {isLoadingLocations ? (
                            <option>Loading locations...</option>
                          ) : (
                            availableLocations.map(location => (
                              <option key={location} value={location.toLowerCase()}>
                                {location.toUpperCase()}
                              </option>
                            ))
                          )}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center">
                          <i className="fas fa-chevron-down text-gray-400"></i>
                        </div>
                      </div>
                    </div>

                    {/* Tab Buttons with improved styling */}
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => setActiveTab('prices')}
                        className={`px-5 py-2.5 rounded-lg font-medium transition-all duration-300 flex items-center ${
                          activeTab === 'prices' 
                            ? 'bg-blue-600 text-white shadow-md scale-105 hover:bg-blue-700' 
                            : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-blue-600'
                        }`}
                      >
                        <i className="fas fa-dollar-sign mr-2"></i>
                        Price Stats
                      </button>
                      <button
                        onClick={() => setActiveTab('properties')}
                        className={`px-5 py-2.5 rounded-lg font-medium transition-all duration-300 flex items-center ${
                          activeTab === 'properties' 
                            ? 'bg-blue-600 text-white shadow-md scale-105 hover:bg-blue-700' 
                            : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-blue-600'
                        }`}
                      >
                        <i className="fas fa-home mr-2"></i>
                        Property
                      </button>
                      <button
                        onClick={() => setActiveTab('rooms')}
                        className={`px-5 py-2.5 rounded-lg font-medium transition-all duration-300 flex items-center ${
                          activeTab === 'rooms' 
                            ? 'bg-blue-600 text-white shadow-md scale-105 hover:bg-blue-700' 
                            : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-blue-600'
                        }`}
                      >
                        <i className="fas fa-door-open mr-2"></i>
                        Rooms
                      </button>
                      <button
                        onClick={() => setActiveTab('years')}
                        className={`px-5 py-2.5 rounded-lg font-medium transition-all duration-300 flex items-center ${
                          activeTab === 'years' 
                            ? 'bg-blue-600 text-white shadow-md scale-105 hover:bg-blue-700' 
                            : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-blue-600'
                        }`}
                      >
                        <i className="fas fa-calendar-alt mr-2"></i>
                        Years
                      </button>
                    </div>
                  </div>
                </div>

                <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-6 shadow-inner">
                  {renderActiveTabContent()}
                </div>
              </div>
            </div>
            
            <div className="mt-6 text-center">
              <p className="text-gray-600 text-sm italic">
                Data based on analysis of real estate listings in {selectedLocation.toUpperCase()}. 
                Last updated: {new Date().toLocaleDateString()}
              </p>
            </div>
          </div>
        </section>

        {/* Trusted By Section */}
        <section className="bg-gray-50 py-16 md:py-24">
          <div className="container mx-auto px-6">
            <div className="text-center mb-12">
              <span className="inline-block py-1 px-3 rounded-full bg-blue-50 text-xs font-medium text-blue-600 mb-3 fade-in">
                GLOBAL PRESENCE
              </span>
              <h2 className="text-3xl font-bold text-gray-800 mb-4 fade-in">Where We Operate</h2>
              <p className="text-gray-600 max-w-2xl mx-auto fade-in" style={{ transitionDelay: '0.1s' }}>
                Providing cutting-edge real estate analytics across key global markets with localized insights
              </p>
            </div>
            <div className="flex flex-wrap justify-center items-stretch gap-6 md:gap-8">
              {/* USA Card */}
              <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden w-full md:w-80 fade-in" style={{ transitionDelay: '0.2s' }}>
                <div className="relative p-6">
                  <div className="absolute top-0 right-0 -mt-8 -mr-8 w-24 h-24 bg-blue-200 rounded-full opacity-30"></div>
                  <div className="flex items-center justify-center flex-col">
                    <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full shadow-md mb-4">
                      <span className="text-3xl" role="img" aria-label="USA Flag">🇺🇸</span>
                </div>
                  <div className="text-center">
                    <h3 className="text-xl font-bold text-gray-800">United States</h3>
                    <p className="text-blue-600 font-medium">North America</p>
                </div>
              </div>
              </div>
              </div>
              
              {/* UAE Card */}
              <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden w-full md:w-80 fade-in" style={{ transitionDelay: '0.3s' }}>
                <div className="relative p-6">
                  <div className="absolute top-0 right-0 -mt-8 -mr-8 w-24 h-24 bg-purple-200 rounded-full opacity-30"></div>
                  <div className="flex items-center justify-center flex-col">
                    <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full shadow-md mb-4">
                      <span className="text-3xl" role="img" aria-label="UAE Flag">🇦🇪</span>
                    </div>
                    <div className="text-center">
                      <h3 className="text-xl font-bold text-gray-800">UAE</h3>
                      <p className="text-purple-600 font-medium">Middle East</p>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* KSA Card */}
              <div className="bg-gradient-to-br from-green-50 to-green-100 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden w-full md:w-80 fade-in" style={{ transitionDelay: '0.4s' }}>
                <div className="relative p-6">
                  <div className="absolute top-0 right-0 -mt-8 -mr-8 w-24 h-24 bg-green-200 rounded-full opacity-30"></div>
                  <div className="flex items-center justify-center flex-col">
                    <div className="w-16 h-16 flex items-center justify-center bg-white rounded-full shadow-md mb-4">
                      <span className="text-3xl" role="img" aria-label="Saudi Arabia Flag">🇸🇦</span>
                    </div>
                    <div className="text-center">
                      <h3 className="text-xl font-bold text-gray-800">KSA</h3>
                      <p className="text-green-600 font-medium">Middle East</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

          {/* Core Features Section */}
          <section id="features" className="py-24 bg-gray-50">
          <div className="container mx-auto px-6">
              <div className="text-center max-w-3xl mx-auto mb-16 fade-in">
                <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-blue-50">
                  <span className="px-3 py-1 text-xs font-medium text-primary-dark">PLATFORM FEATURES</span>
                </div>
                <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900">Comprehensive Analytics Suite</h2>
                <p className="text-lg text-gray-600">Discover how our AI-powered platform revolutionizes real estate analytics with cutting-edge features.</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* Market Intelligence */}
                <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.1s' }}>
                  <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                    <i className="fas fa-chart-line text-primary-dark text-xl"></i>
                </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">Market Intelligence</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Real-time market data analysis
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Demographic trend analysis
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Supply and demand forecasting
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Location-based performance metrics
                    </li>
                  </ul>
              </div>

                {/* Property Valuation */}
                <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.2s' }}>
                  <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                    <i className="fas fa-home text-purple-600 text-xl"></i>
                </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">Property Valuation</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Automated valuation models
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Rental yield analysis
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      ROI calculator
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Price trend prediction
                    </li>
                  </ul>
              </div>

                {/* Development Planning */}
                <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.3s' }}>
                  <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                    <i className="fas fa-city text-green-600 text-xl"></i>
                </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">Development Planning</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Site feasibility analysis
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Zoning compliance checker
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Development potential calculator
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Environmental risk analysis
                    </li>
                  </ul>
              </div>

                {/* Investment Analytics */}
                <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.4s' }}>
                  <div className="w-12 h-12 bg-yellow-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                    <i className="fas fa-chart-pie text-yellow-600 text-xl"></i>
                </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">Investment Analytics</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Investment opportunity scoring
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Risk assessment models
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Portfolio performance tracking
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Cash flow projections
                    </li>
                  </ul>
              </div>

                {/* AI-Powered Insights */}
                <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.5s' }}>
                  <div className="w-12 h-12 bg-red-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                    <i className="fas fa-brain text-red-600 text-xl"></i>
                </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">AI-Powered Insights</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      ML-based price predictions
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Demand forecasting
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Market sentiment analysis
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Pattern recognition
                    </li>
                  </ul>
              </div>

                {/* Visualization Tools */}
                <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 fade-in" style={{ transitionDelay: '0.6s' }}>
                  <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                    <i className="fas fa-map text-indigo-600 text-xl"></i>
                </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">Visualization Tools</h3>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Interactive heat maps
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      3D development visualization
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Demographic distribution maps
                    </li>
                    <li className="flex items-center justify-center text-center">
                      <i className="fas fa-check text-green-500 mr-2"></i>
                      Time-series visualization
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </section>

          {/* Success Stories Section */}
          <section className="py-24 bg-white">
            <div className="container mx-auto px-6">
              <div className="text-center max-w-3xl mx-auto mb-16 fade-in">
                <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-blue-50">
                  <span className="px-3 py-1 text-xs font-medium text-primary-dark">SUCCESS STORIES</span>
                </div>
                <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900">Real Results, Real Impact</h2>
                <p className="text-lg text-gray-600">See how leading real estate professionals are transforming their business with Winpra.</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Real Estate Developer Case */}
                <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 fade-in" style={{ transitionDelay: '0.1s' }}>
                  <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-6 mx-auto">
                    <i className="fas fa-building text-blue-600 text-2xl"></i>
                  </div>
                  <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Real Estate Developer</h3>
                  <p className="text-gray-600 mb-6 text-center">Optimized land acquisition process leading to significant cost savings and faster decision-making.</p>
                  <ul className="space-y-3">
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-chart-line text-green-500 mr-3"></i>
                      <span>15% reduction in acquisition costs</span>
                    </li>
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-clock text-green-500 mr-3"></i>
                      <span>30% faster decision-making</span>
                    </li>
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-search text-green-500 mr-3"></i>
                      <span>Identified overlooked opportunities</span>
                    </li>
                  </ul>
                </div>

                {/* Investment Fund Case */}
                <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 fade-in" style={{ transitionDelay: '0.2s' }}>
                  <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mb-6 mx-auto">
                    <i className="fas fa-chart-pie text-purple-600 text-2xl"></i>
                  </div>
                  <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Investment Fund</h3>
                  <p className="text-gray-600 mb-6 text-center">Enhanced portfolio performance through data-driven decision making and risk management.</p>
                  <ul className="space-y-3">
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-chart-line text-green-500 mr-3"></i>
                      <span>22% increase in portfolio performance</span>
                    </li>
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-bullseye text-green-500 mr-3"></i>
                      <span>Identified emerging opportunities</span>
                    </li>
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-shield-alt text-green-500 mr-3"></i>
                      <span>Reduced risk exposure</span>
                    </li>
                  </ul>
                </div>

                {/* Property Management Case */}
                <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 fade-in" style={{ transitionDelay: '0.3s' }}>
                  <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6 mx-auto">
                    <i className="fas fa-home text-green-600 text-2xl"></i>
                  </div>
                  <h3 className="text-xl font-bold mb-4 text-gray-900 text-center">Property Management Firm</h3>
                  <p className="text-gray-600 mb-6 text-center">Improved operational efficiency and tenant satisfaction through data-driven insights.</p>
                  <ul className="space-y-3">
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-chart-line text-green-500 mr-3"></i>
                      <span>18% increase in rental yield</span>
                    </li>
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-percentage text-green-500 mr-3"></i>
                      <span>25% reduction in vacancy rates</span>
                    </li>
                    <li className="flex items-center text-gray-700">
                      <i className="fas fa-smile text-green-500 mr-3"></i>
                      <span>Improved tenant satisfaction</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="text-center mt-12 fade-in" style={{ transitionDelay: '0.4s' }}>
                <a href="#demo-form" className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary-dark transition duration-300 transform hover:-translate-y-1 fade-in" style={{ transitionDelay: '0.4s' }}>
                  <span>Start Your Success Story</span>
                  <i className="fas fa-arrow-right ml-2"></i>
                </a>
              </div>
            </div>
          </section>

          {/* Market Insights Section */}
        <section className="bg-gray-100 py-16 md:py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 fade-in">
                How Winpra Works
              </h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto fade-in" style={{ transitionDelay: '0.2s' }}>
                Our comprehensive five-step process makes it easy to get started with AI-powered real estate analytics.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
              <div className="text-center fade-in" style={{ transitionDelay: '0.3s' }}>
                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center text-2xl font-bold text-primary mx-auto mb-6 shadow-md">1</div>
                <h3 className="text-xl font-bold mb-3">Schedule a Demo</h3>
                <p className="text-gray-600">
                  Experience the power of Winpra firsthand through a personalized demo of our platform.
                </p>
              </div>

              <div className="text-center fade-in" style={{ transitionDelay: '0.4s' }}>
                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center text-2xl font-bold text-primary mx-auto mb-6 shadow-md">2</div>
                <h3 className="text-xl font-bold mb-3">Implementation Planning</h3>
                <p className="text-gray-600">
                  Our team works with you to plan your implementation and integrate with your existing systems.
                </p>
              </div>

              <div className="text-center fade-in" style={{ transitionDelay: '0.5s' }}>
                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center text-2xl font-bold text-primary mx-auto mb-6 shadow-md">3</div>
                <h3 className="text-xl font-bold mb-3">Data Integration</h3>
                <p className="text-gray-600">
                  We connect your existing systems and data sources to provide comprehensive analytics.
                </p>
              </div>

              <div className="text-center fade-in" style={{ transitionDelay: '0.6s' }}>
                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center text-2xl font-bold text-primary mx-auto mb-6 shadow-md">4</div>
                <h3 className="text-xl font-bold mb-3">Training & Onboarding</h3>
                <p className="text-gray-600">
                  Your team receives comprehensive training to maximize the value of our platform.
                </p>
              </div>

              <div className="text-center fade-in" style={{ transitionDelay: '0.7s' }}>
                <div className="bg-white w-16 h-16 rounded-full flex items-center justify-center text-2xl font-bold text-primary mx-auto mb-6 shadow-md">5</div>
                <h3 className="text-xl font-bold mb-3">Ongoing Support</h3>
                <p className="text-gray-600">
                  Our support team ensures your continued success with regular updates and assistance.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action (CTA) Section */}
        <section className="bg-gradient-to-r from-blue-800 to-indigo-900 text-white py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 fade-in">
              Ready to Transform Your Real Estate Strategy?
            </h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto fade-in" style={{ transitionDelay: '0.2s' }}>
              Join leading real estate professionals who are making data-driven decisions with Winpra.
            </p>
              <a href="#contact" className="inline-flex bg-white text-primary-dark hover:bg-gray-100 font-bold py-3 px-8 rounded-full transition duration-300 transform hover:-translate-y-1 fade-in" style={{ transitionDelay: '0.4s' }}>
              Get Started Today
            </a>
          </div>
        </section>

          {/* Demo Request Form Section */}
          <section id="demo-form" className="py-24 bg-gradient-to-br from-primary-dark to-primary-light text-white relative overflow-hidden">
            {/* Background decoration */}
            <div className="absolute inset-0 opacity-10">
              <div className="absolute -top-24 -left-24 w-96 h-96 bg-white rounded-full blur-3xl"></div>
              <div className="absolute top-1/2 -right-24 w-80 h-80 bg-white rounded-full blur-3xl"></div>
              <div className="absolute bottom-0 left-1/3 w-64 h-64 bg-white rounded-full blur-3xl"></div>
                      </div>
                      
            <div className="container mx-auto px-6 relative z-10">
              <div className="max-w-4xl mx-auto">
                <div className="text-center mb-12 fade-in">
                  <span className="inline-block py-1.5 px-4 rounded-full bg-white/20 text-sm font-medium mb-6">
                    GET STARTED
                  </span>
                  <h2 className="text-3xl md:text-4xl font-bold mb-4">
                    Transform Your Real Estate Analytics Today
                  </h2>
                  <p className="text-xl text-blue-100 max-w-2xl mx-auto">
                    Schedule a personalized demo to see how Winpra can revolutionize your real estate decisions.
                  </p>
                      </div>
                      
                <form onSubmit={handleSubmit} className="bg-white rounded-2xl p-8 md:p-10 shadow-2xl fade-in bg-opacity-90 backdrop-blur-sm border border-white/20">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="relative">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                        Full Name
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <i className="fas fa-user text-gray-400"></i>
                        </div>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200 shadow-sm"
                          required
                          placeholder="Enter your full name"
                        />
                      </div>
                      </div>

                    <div className="relative">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        Business Email
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <i className="fas fa-envelope text-gray-400"></i>
                        </div>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200 shadow-sm"
                          required
                          placeholder="your.email@company.com"
                        />
                      </div>
                      </div>

                    <div className="relative">
                      <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
                        Company Name
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <i className="fas fa-building text-gray-400"></i>
                        </div>
                        <input
                          type="text"
                          id="company"
                          name="company"
                          value={formData.company}
                          onChange={handleInputChange}
                          className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200 shadow-sm"
                          required
                          placeholder="Your company name"
                        />
                      </div>
                    </div>

                    <div className="relative">
                      <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">
                        Job Role
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <i className="fas fa-briefcase text-gray-400"></i>
                        </div>
                        <select
                          id="role"
                          name="role"
                          value={formData.role}
                          onChange={handleInputChange}
                          className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200 shadow-sm appearance-none"
                          required
                        >
                          <option value="">Select your role</option>
                          <option value="developer">Real Estate Developer</option>
                          <option value="investor">Real Estate Investor</option>
                          <option value="broker">Real Estate Broker</option>
                          <option value="manager">Property Manager</option>
                          <option value="analyst">Market Analyst</option>
                          <option value="other">Other</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <i className="fas fa-chevron-down text-gray-400"></i>
                        </div>
                      </div>
                    </div>

                    <div className="md:col-span-2">
                      <label htmlFor="interests" className="block text-sm font-medium text-gray-700 mb-3">
                        Areas of Interest
                      </label>
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                        {['Market Intelligence', 'Property Valuation', 'Development Planning', 'Investment Analytics', 'AI Insights', 'Visualization Tools'].map((interest) => (
                          <label key={interest} className="flex items-center space-x-2 text-gray-600 bg-gray-50 p-2 rounded-lg hover:bg-gray-100 transition-colors">
                            <input
                              type="checkbox"
                              name="interests"
                              value={interest}
                              className="rounded border-gray-300 text-primary focus:ring-primary"
                            />
                            <span className="text-sm">{interest}</span>
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className="md:col-span-2">
                      <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                        Additional Information
                      </label>
                      <div className="relative">
                        <div className="absolute top-3 left-3 flex items-start pointer-events-none">
                          <i className="fas fa-comment-alt text-gray-400 mt-1"></i>
                        </div>
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          rows="4"
                          className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200 shadow-sm"
                          placeholder="Tell us about your specific needs or questions..."
                        ></textarea>
                      </div>
                        </div>
                  </div>

                  <div className="mt-8">
                      <button
                        type="submit"
                        disabled={formStatus.isSubmitting}
                      className="w-full bg-gradient-to-r from-primary to-primary-dark text-white font-medium py-4 px-6 rounded-lg transition duration-200 flex items-center justify-center shadow-lg hover:shadow-xl transform hover:-translate-y-1"
                      >
                        {formStatus.isSubmitting ? (
                          <>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          Processing...
                          </>
                        ) : (
                        <>
                          <span className="mr-2">Request Demo</span>
                          <i className="fas fa-arrow-right"></i>
                        </>
                        )}
                      </button>
                  </div>

                  {formStatus.message && (
                    <div className={`mt-6 p-4 rounded-lg ${formStatus.isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'} flex items-center shadow-md`}>
                      <i className={`fas ${formStatus.isError ? 'fa-exclamation-circle' : 'fa-check-circle'} mr-3 text-xl`}></i>
                      <span className="font-medium">{formStatus.message}</span>
                </div>
                  )}
                </form>
            </div>
          </div>
        </section>

          {/* Back to Top Button with Animation */}
          {showBackToTop && (
        <button 
          onClick={scrollToTop}
              className="fixed bottom-8 right-8 bg-primary text-white p-3 rounded-full shadow-lg hover:bg-primary-dark transition-colors duration-300 z-50 animate-bounce hover:animate-none"
          aria-label="Back to top"
        >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7"></path>
          </svg>
        </button>
          )}

          {/* Add custom animations CSS */}
          <style jsx="true">{`
            @keyframes blob {
              0% { transform: translate(0px, 0px) scale(1); }
              33% { transform: translate(30px, -50px) scale(1.1); }
              66% { transform: translate(-20px, 20px) scale(0.9); }
              100% { transform: translate(0px, 0px) scale(1); }
            }
            .animate-blob {
              animation: blob 7s infinite;
            }
            .animation-delay-2000 {
              animation-delay: 2s;
            }
            .animation-delay-4000 {
              animation-delay: 4s;
            }
            .hover-grow {
              transition: transform 0.3s ease, box-shadow 0.3s ease;
            }
            .hover-grow:hover {
              transform: translateY(-10px);
              box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
            }
          `}</style>
      </div>
      </>
    );
};

export default Home;