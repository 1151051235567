import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <div className="pt-20 pb-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-indigo-800 bg-clip-text text-transparent">
            Enterprise-Grade Real Estate Development Solutions
          </h1>
          <p className="text-xl text-gray-600 mb-12">
            Contact our sales team to learn more about our pricing options and find the perfect plan for your needs
          </p>
          </div>
          
        {/* Plan summary cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16 max-w-5xl mx-auto">
          {/* Basic Plan */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden border-t-4 border-blue-500 hover:shadow-2xl transition-all duration-300 flex flex-col h-full transform hover:-translate-y-1">
            <div className="p-6">
              <div className="flex items-center justify-center mb-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <i className="fas fa-chart-line text-blue-600 text-xl"></i>
                </div>
              </div>
              <h3 className="text-xl font-bold text-center text-gray-800 mb-3">Basic (Platform)</h3>
              <div className="text-center mb-6">
                <div className="text-3xl font-bold text-gray-900">Contact Sales</div>
                <div className="text-sm text-gray-500 mt-1">per asset/month</div>
              </div>
              <p className="text-gray-600 mb-6 text-center">Perfect for individual developers and small real estate firms</p>
              <div className="bg-gray-50 p-4 rounded-xl mb-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">Real-Time Data Integration</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">Land Valuation</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">Due Diligence for 30 assets/month</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">Construction Management</span>
                  </li>
                </ul>
              </div>
              <button
                onClick={() => navigate('/contact')}
                className="block w-full text-center py-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-xl transition-colors"
              >
                Contact Sales
              </button>
            </div>
        </div>
        
          {/* Medium Plan */}
          <div className="bg-white rounded-2xl shadow-2xl overflow-hidden border-t-4 border-purple-500 transform scale-105 z-10 hover:shadow-2xl transition-all duration-300 flex flex-col h-full hover:-translate-y-1">
            <div className="p-6">
              <div className="flex items-center justify-center mb-4">
                <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center">
                  <i className="fas fa-cube text-purple-600 text-xl"></i>
                </div>
              </div>
              <h3 className="text-xl font-bold text-center text-gray-800 mb-3">Medium (Advanced Simulation)</h3>
              <div className="text-center mb-6">
                <div className="text-3xl font-bold text-gray-900">Contact Sales</div>
                <div className="text-sm text-gray-500 mt-1">per asset/month</div>
              </div>
              <p className="text-gray-600 mb-6 text-center">Ideal for growing real estate development teams</p>
              <div className="bg-purple-50 p-4 rounded-xl mb-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">All Basic features</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">3D Map Integration</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">Advanced Construction Management</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">AI-driven Asset Management</span>
                  </li>
                </ul>
              </div>
              <button
                onClick={() => navigate('/contact')}
                className="block w-full text-center py-4 bg-purple-500 hover:bg-purple-600 text-white font-semibold rounded-xl transition-colors"
              >
                Contact Sales
              </button>
            </div>
          </div>

          {/* Pro Plan */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden border-t-4 border-indigo-600 hover:shadow-2xl transition-all duration-300 flex flex-col h-full transform hover:-translate-y-1">
            <div className="p-6">
              <div className="flex items-center justify-center mb-4">
                <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center">
                  <i className="fas fa-brain text-indigo-600 text-xl"></i>
                </div>
              </div>
              <h3 className="text-xl font-bold text-center text-gray-800 mb-3">Pro (Core AI)</h3>
              <div className="text-center mb-6">
                <div className="text-3xl font-bold text-gray-900">Contact Sales</div>
                <div className="text-sm text-gray-500 mt-1">per asset/month</div>
              </div>
              <p className="text-gray-600 mb-6 text-center">For large agencies and investment firms</p>
              <div className="bg-indigo-50 p-4 rounded-xl mb-6">
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">All Medium features</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">ML Driven Market Analysis</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">Advanced Decision Support</span>
                  </li>
                  <li className="flex items-start">
                    <i className="fas fa-check-circle text-green-500 mt-1 mr-3 flex-shrink-0"></i>
                    <span className="text-left">Portfolio Engineering</span>
                    </li>
                </ul>
              </div>
            <button
                onClick={() => navigate('/contact')}
                className="block w-full text-center py-4 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-xl transition-colors"
              >
                Contact Sales
            </button>
            </div>
          </div>
        </div>
        
        <div className="mt-20 bg-gradient-to-r from-blue-600 to-indigo-800 text-white rounded-xl p-10 max-w-5xl mx-auto shadow-xl">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Real Estate Development Process?</h2>
            <p className="text-xl mb-8 opacity-90">
              Our AI-powered platform helps you make data-driven decisions at every stage of development, from land acquisition to asset management.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link 
              to="/contact" 
                className="inline-block bg-white text-blue-700 hover:bg-gray-100 font-bold py-4 px-8 rounded-lg transition-colors shadow-lg hover:shadow-xl"
              >
                <i className="fas fa-calendar-alt mr-2"></i>
                Schedule a Demo
              </Link>
              <Link 
                to="/contact" 
                className="inline-block bg-transparent border-2 border-white text-white hover:bg-white hover:text-blue-700 font-bold py-4 px-8 rounded-lg transition-colors shadow-lg hover:shadow-xl"
              >
                <i className="fas fa-rocket mr-2"></i>
                Get Started
            </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing; 