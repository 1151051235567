import axios from 'axios';

// Backend API URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL || 'https://lapi.winpra.com/api';

// Base API configuration
const apiClient = axios.create({
  baseURL: BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Response interceptor for error handling
apiClient.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error.message);
    return Promise.reject(error);
  }
);

// Fetch available locations
export const getAvailableLocations = async () => {
  try {
    const response = await apiClient.get('/locations');
    return response.data.locations;
  } catch (error) {
    console.error('Failed to fetch available locations:', error);
    throw error;
  }
};

// Fetch market trends data
export const getMarketTrends = async (location) => {
  try {
    const response = await apiClient.get(`/market-trends/${location.toLowerCase()}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch market trends:', error);
    throw error;
  }
};

// Fetch property growth data
export const getPropertyGrowth = async (location) => {
  try {
    const response = await apiClient.get(`/property-growth/${location.toLowerCase()}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch property growth data:', error);
    throw error;
  }
};

// Fetch demographic data
export const getDemographicData = async (location) => {
  try {
    const response = await apiClient.get(`/demographics/${location.toLowerCase()}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch demographic data:', error);
    throw error;
  }
};

// Get market statistics
export const getMarketStats = async (location) => {
  try {
    const response = await apiClient.get(`/market-stats/${location.toLowerCase()}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch market stats:', error);
    throw error;
  }
};

// Get property listings
export const getPropertyListings = async (location, limit = 10) => {
  try {
    const response = await apiClient.get(`/properties/${location.toLowerCase()}`, {
      params: { limit }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch property listings:', error);
    throw error;
  }
};

// Create an axios instance with default config for forms/contact
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://lapi.winpra.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// API functions for contact forms
export const submitContactForm = async (data) => {
  try {
    const response = await api.post('/api/contact', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitDemoRequest = async (data) => {
  try {
    const response = await api.post('/api/demo-request', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch price statistics
export const getPriceStatistics = async (location) => {
  try {
    const response = await apiClient.get(`/price-stats/${location}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching price statistics:', error);
    throw error;
  }
};

// Fetch property statistics
export const getPropertyStatistics = async (location) => {
  try {
    const response = await apiClient.get(`/property-stats/${location}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching property statistics:', error);
    throw error;
  }
};

// Fetch room statistics
export const getRoomStatistics = async (location) => {
  try {
    const response = await apiClient.get(`/room-stats/${location}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching room statistics:', error);
    throw error;
  }
};

// Fetch year statistics
export const getYearStatistics = async (location) => {
  try {
    const response = await apiClient.get(`/year-stats/${location}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching year statistics:', error);
    throw error;
  }
};

export default api;