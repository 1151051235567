import React from 'react';

const About = () => {
  return (
    <div className="pt-20 pb-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-8 text-center">About Winpra</h1>
          
          <div className="bg-white rounded-lg shadow-lg p-8 mb-10">
            <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
            <p className="text-gray-700 mb-6">
              At Winpra, our mission is to revolutionize the real estate industry by providing AI-driven insights and analytics that empower professionals to make data-driven decisions. We believe that by combining cutting-edge technology with deep industry expertise, we can help our clients navigate the complex real estate market with confidence.
            </p>
            
            <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
            <p className="text-gray-700 mb-6">
              Founded in 2020 by a team of real estate professionals and data scientists, Winpra was born out of the recognition that the real estate industry was ripe for technological disruption. Our founders experienced firsthand the challenges of making investment decisions based on incomplete or outdated information, and they set out to create a solution that would bring the power of artificial intelligence to real estate analysis.
            </p>
            <p className="text-gray-700 mb-6">
              Since our inception, we've grown from a small startup to a trusted partner for real estate professionals across the country. Our platform has evolved based on user feedback and industry needs, and we continue to innovate and expand our offerings to better serve our clients.
            </p>
            
            <h2 className="text-2xl font-semibold mb-4">Our Core Expertise</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-blue-50 rounded-xl p-6 shadow-md transition-all duration-300">
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                  <i className="fas fa-brain text-blue-600 text-xl"></i>
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">AI-Driven Analytics</h3>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-center justify-center text-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Machine learning algorithms
                  </li>
                  <li className="flex items-center justify-center text-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Predictive modeling
                  </li>
                  <li className="flex items-center justify-center text-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Natural language processing
                  </li>
                </ul>
              </div>
              
              <div className="bg-purple-50 rounded-xl p-6 shadow-md transition-all duration-300">
                <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                  <i className="fas fa-chart-pie text-purple-600 text-xl"></i>
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-900 text-center">Data Science</h3>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-center justify-center text-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Big data processing
                  </li>
                  <li className="flex items-center justify-center text-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Statistical analysis
                  </li>
                  <li className="flex items-center justify-center text-center">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    Data visualization
                  </li>
                </ul>
              </div>
            </div>
            
            <h2 className="text-2xl font-semibold mb-4">Our Team</h2>
            <p className="text-gray-700 mb-6">
              Winpra is powered by a diverse team of experts in real estate, data science, software engineering, and business strategy. Our team members bring decades of combined experience from leading companies and institutions, united by a shared passion for innovation and excellence.
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
              <div className="text-center">
                <div className="w-32 h-32 rounded-full bg-gray-300 mx-auto mb-4"></div>
                <h3 className="font-semibold">Jane Doe</h3>
                <p className="text-gray-600">CEO & Co-Founder</p>
              </div>
              <div className="text-center">
                <div className="w-32 h-32 rounded-full bg-gray-300 mx-auto mb-4"></div>
                <h3 className="font-semibold">John Smith</h3>
                <p className="text-gray-600">CTO & Co-Founder</p>
              </div>
              <div className="text-center">
                <div className="w-32 h-32 rounded-full bg-gray-300 mx-auto mb-4"></div>
                <h3 className="font-semibold">Emily Johnson</h3>
                <p className="text-gray-600">Chief Data Scientist</p>
              </div>
            </div>
          </div>
          
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="text-xl font-semibold mb-2">Innovation</h3>
                <p className="text-gray-700">
                  We continuously push the boundaries of what's possible in real estate analytics, leveraging the latest advancements in AI and data science.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Integrity</h3>
                <p className="text-gray-700">
                  We are committed to honesty, transparency, and ethical practices in all our interactions with clients and partners.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Excellence</h3>
                <p className="text-gray-700">
                  We strive for excellence in everything we do, from the accuracy of our analytics to the quality of our customer service.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Collaboration</h3>
                <p className="text-gray-700">
                  We believe in the power of collaboration, both within our team and with our clients, to drive innovation and success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 