import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header 
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled 
          ? 'bg-white shadow-md py-3' 
          : location.pathname === '/' 
            ? 'bg-white/90 backdrop-blur-sm py-4' 
            : 'bg-white shadow-sm py-3'
      }`}
    >
      <div className="container mx-auto px-6 flex justify-between items-center">
        <Link to="/" className="flex items-center" onClick={closeMenu}>
          <img 
            src={Logo} 
            alt="Winpra Logo" 
            className="h-10 sm:h-12 rounded-lg" 
          />
        </Link>

        {/* Desktop Menu */}
        <nav className="hidden md:flex items-center space-x-8">
          <Link 
            to="/" 
            className={`text-base font-medium transition-colors ${
              location.pathname === '/' 
                ? 'text-primary' 
                : 'text-gray-700 hover:text-primary'
            }`}
            onClick={closeMenu}
          >
            Home
          </Link>
          <Link 
            to="/features" 
            className={`text-base font-medium transition-colors ${
              location.pathname === '/features' 
                ? 'text-primary' 
                : 'text-gray-700 hover:text-primary'
            }`}
            onClick={closeMenu}
          >
            Features
          </Link>
          <Link 
            to="/pricing" 
            className={`text-base font-medium transition-colors ${
              location.pathname === '/pricing' 
                ? 'text-primary' 
                : 'text-gray-700 hover:text-primary'
            }`}
            onClick={closeMenu}
          >
            Pricing
          </Link>
          <Link 
            to="/contact" 
            className={`text-base font-medium transition-colors ${
              location.pathname === '/contact' 
                ? 'text-primary' 
                : 'text-gray-700 hover:text-primary'
            }`}
            onClick={closeMenu}
          >
            Contact
          </Link>
          <Link 
            to="/contact" 
            className={`bg-primary hover:bg-primary-dark text-white px-6 py-2.5 rounded-lg font-medium transition-colors shadow-sm hover:shadow-md flex items-center`}
            onClick={closeMenu}
          >
            <span>Get Started</span>
            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
            </svg>
          </Link>
        </nav>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden text-gray-700 focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <svg 
            className="w-6 h-6" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            {isMenuOpen ? (
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div 
        className={`md:hidden transition-all duration-300 ease-in-out overflow-hidden ${
          isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="container mx-auto px-6 py-4 bg-white shadow-lg rounded-b-lg">
          <nav className="flex flex-col space-y-4">
            <Link 
              to="/" 
              className={`text-base font-medium transition-colors ${
                location.pathname === '/' 
                  ? 'text-primary' 
                  : 'text-gray-700 hover:text-primary'
              }`}
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link 
              to="/features" 
              className={`text-base font-medium transition-colors ${
                location.pathname === '/features' 
                  ? 'text-primary' 
                  : 'text-gray-700 hover:text-primary'
              }`}
              onClick={closeMenu}
            >
              Features
            </Link>
            <Link 
              to="/pricing" 
              className={`text-base font-medium transition-colors ${
                location.pathname === '/pricing' 
                  ? 'text-primary' 
                  : 'text-gray-700 hover:text-primary'
              }`}
              onClick={closeMenu}
            >
              Pricing
            </Link>
            <Link 
              to="/contact" 
              className={`text-base font-medium transition-colors ${
                location.pathname === '/contact' 
                  ? 'text-primary' 
                  : 'text-gray-700 hover:text-primary'
              }`}
              onClick={closeMenu}
            >
              Contact
            </Link>
            <Link 
              to="/contact" 
              className="bg-primary hover:bg-primary-dark text-white px-6 py-3 rounded-lg font-medium transition-colors shadow-sm hover:shadow-md text-center"
              onClick={closeMenu}
            >
              Get Started
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header; 