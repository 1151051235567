import React, { useState, useEffect, useRef } from 'react';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend as ChartJSLegend, Filler } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { format, addHours } from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  LineChart, AreaChart, Area, BarChart,
  XAxis, YAxis, CartesianGrid, ResponsiveContainer,
  Legend as RechartsLegend, PieChart, Pie, Cell
} from 'recharts';

// Register required Chart.js components and adapters
ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  ChartJSLegend,
  Filler
);

// Configure adapter locale properly
// Note: We don't set it globally as this causes ESLint errors
// We'll handle the locale in the options for each chart instead

// Custom tooltip component (animated and styled)
const customTooltip = {
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  titleColor: '#111827',
  titleFont: {
    weight: 'bold',
    size: 14
  },
  bodyColor: '#4B5563',
  bodyFont: {
    size: 13
  },
  borderColor: 'rgba(148, 163, 184, 0.2)',
  borderWidth: 1,
  padding: 12,
  boxPadding: 6,
  callbacks: {
    label: function(context) {
      if (!context || !context.parsed) return '';
      
      let label = context.dataset.label || '';
      if (label) {
        label += ': ';
      }
      
      // Handle cases where parsed values might be undefined
      let value;
      if (context.parsed.y !== undefined) {
        value = context.parsed.y;
      } else if (context.parsed !== undefined && typeof context.parsed === 'number') {
        value = context.parsed;
      } else {
        return label + 'N/A';
      }
      
      // Format the value based on the type
      if (label.includes('Change')) {
        return label + value.toFixed(2) + '%';
      } else {
        return label + value.toFixed(2);
      }
    },
    title: function(tooltipItems) {
      if (!tooltipItems || !tooltipItems.length) {
        return '';
      }
      
      const item = tooltipItems[0];
      if (!item) return '';
      
      // Simple date formatting to avoid issues
      if (item.label) {
        try {
          const dateValue = new Date(item.label);
          if (!isNaN(dateValue.getTime())) {
            return format(dateValue, 'MMM yyyy');
          }
        } catch (e) {}
      }
      
      return item.label || '';
    }
  }
};

// Enhanced loading spinner with animation
const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-full">
    <div className="relative w-20 h-20">
      <div className="absolute top-0 left-0 right-0 bottom-0 border-4 border-blue-200 rounded-full"></div>
      <div className="absolute top-0 left-0 right-0 bottom-0 border-4 border-transparent border-t-blue-500 rounded-full animate-spin"></div>
    </div>
    <p className="mt-4 text-gray-500 font-medium">Loading chart data...</p>
  </div>
);

// Skeleton loader for charts
const SkeletonLoader = () => (
  <div className="animate-pulse h-full w-full flex flex-col">
    <div className="h-8 bg-gray-200 rounded-md w-1/3 mx-auto mb-4"></div>
    <div className="flex items-end h-[calc(100%-2rem)] px-4">
      {[...Array(12)].map((_, i) => (
        <div key={i} className="flex-1 bg-gray-200 rounded-t-md mx-1" style={{ height: `${Math.random() * 70 + 10}%` }}></div>
      ))}
    </div>
    <div className="h-6 bg-gray-200 rounded-md w-full mt-2"></div>
  </div>
);

// Error message component
const ErrorDisplay = ({ message }) => (
  <div className="flex justify-center items-center h-full">
    <div className="text-red-500 bg-red-50 p-4 rounded-lg shadow border border-red-200 max-w-md text-center">
      <svg className="w-8 h-8 text-red-500 mx-auto mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
      <p className="font-semibold">Error Loading Chart Data</p>
      <p className="text-sm mt-1">{message}</p>
      <p className="text-xs mt-3">Please try refreshing the page or contact support if the issue persists.</p>
    </div>
  </div>
);

const Charts = () => {
  // Initialize with default empty arrays to prevent null reference errors
  const [chartData, setChartData] = useState({
    indexData: [],
    changeData: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Color configurations
  const colors = {
    primary: '#2563eb',
    secondary: '#7c3aed',
    success: '#059669',
    warning: '#d97706',
    info: '#0284c7',
    error: '#dc2626'
  };

  const gradients = {
    blue: [
      { offset: '0%', color: 'rgba(37, 99, 235, 0.2)' },
      { offset: '100%', color: 'rgba(37, 99, 235, 0)' }
    ],
    purple: [
      { offset: '0%', color: 'rgba(124, 58, 237, 0.2)' },
      { offset: '100%', color: 'rgba(124, 58, 237, 0)' }
    ]
  };

  // Tooltip styles
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-100">
          <p className="text-sm font-medium text-gray-600">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} className="text-sm" style={{ color: entry.color }}>
              {entry.name}: {typeof entry.value === 'number' ? entry.value.toLocaleString() : entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    // Function to fetch CSV data
    const fetchCSVData = async () => {
      try {
        setLoading(true);
        const response = await fetch('/FLSTHPI.csv');
        if (!response.ok) {
          throw new Error(`Failed to fetch CSV: ${response.status}`);
        }
        const csvText = await response.text();
        return csvText;
      } catch (err) {
        setError(`Error fetching CSV data: ${err.message}`);
        return null;
      }
    };

    // Function to parse CSV data
    const parseCSV = (csv) => {
      if (!csv) return [];
      
      const lines = csv.split('\n');
      return lines.slice(1).map(line => {
        if (!line) return null;
        const [date, value] = line.split(',');
        return { date: new Date(date), value: parseFloat(value) };
      }).filter(item => item && !isNaN(item.value)); // Filter out any invalid entries
    };

    // Main function to load chart data
    const loadChartData = async () => {
      try {
        const csvData = await fetchCSVData();
        if (csvData) {
          const data = parseCSV(csvData);
          
          if (data && data.length > 0) {
            // Prepare year-over-year change data for the portfolio performance chart
            // Make sure we have enough data to calculate changes
            const changeData = data.length > 4 ? data.slice(4).map((d, i) => {
              if (!d || !data[i]) return null;
              const prevYearValue = data[i].value;
              return {
                date: d.date,
                change: ((d.value - prevYearValue) / prevYearValue) * 100
              };
            }).filter(Boolean) : [];

            setChartData({
              indexData: data,
              changeData: changeData
            });
          }
        }
      } catch (err) {
        setError(`Error processing chart data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    loadChartData();
  }, []);

  // Create linear gradients for charts
  const createGradient = (ctx, colorStart, colorEnd) => {
    if (!ctx) return null;
    
    try {
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, colorStart);
      gradient.addColorStop(1, colorEnd);
      return gradient;
    } catch (err) {
      console.error('Error creating gradient:', err);
      return colorStart; // Fallback to solid color
    }
  };

  // Common chart options - simplify to avoid errors
  const getCommonOptions = (ctx, title = '') => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 15,
            font: {
              size: 12,
              weight: 'bold'
            }
          }
        },
        tooltip: {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          titleColor: '#111827',
          titleFont: {
            weight: 'bold',
            size: 14
          },
          bodyColor: '#4B5563',
          bodyFont: {
            size: 13
          },
          borderColor: 'rgba(148, 163, 184, 0.2)',
          borderWidth: 1,
          padding: 12,
          boxPadding: 6,
          displayColors: true,
          // Simplified tooltip callbacks
          callbacks: {
            label: function(context) {
              if (!context || !context.parsed) return '';
              
              const label = context.dataset.label || '';
              const value = context.parsed.y !== undefined ? context.parsed.y : context.parsed;
              
              if (value === undefined || value === null) return label;
              
              if (label.includes('Change')) {
                return `${label}: ${value.toFixed(2)}%`;
              } else {
                return `${label}: ${value.toFixed(2)}`;
              }
            }
          }
        },
        title: {
          display: !!title,
          text: title,
          font: {
            size: 16,
            weight: 'bold'
          },
          padding: {
            top: 10,
            bottom: 15
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'year',
            displayFormats: {
              year: 'yyyy'
            }
          },
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            font: {
              size: 11
            },
            color: '#64748b',
            padding: 8
          }
        },
        y: {
          beginAtZero: false,
          grid: {
            color: 'rgba(226, 232, 240, 0.5)',
            drawBorder: false
          },
          ticks: {
            font: {
              size: 11
            },
            color: '#64748b',
            padding: 8
          }
        }
      },
      elements: {
        line: {
          tension: 0.4,
          borderWidth: 3
        },
        point: {
          radius: 0,
          hitRadius: 8,
          hoverRadius: 6,
          borderWidth: 2
        }
      }
    };
  };

  // Hero Chart component
  const HeroChart = () => {
    const chartRef = useRef(null);

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error} />;
    if (chartData.indexData.length === 0) return <LoadingSpinner />;

    // Make sure we have valid data to map
    const safeIndexData = chartData.indexData || [];

    // Process data for simple format
    const chartLabels = safeIndexData.map(d => d.date);
    const chartValues = safeIndexData.map(d => d.value);

    // Simple consistent format
    const data = {
      labels: chartLabels,
      datasets: [{
        label: 'Florida House Price Index',
        data: chartValues,
        borderColor: 'rgb(37, 99, 235)',
        backgroundColor: 'rgba(59, 130, 246, 0.2)',
        fill: true,
        tension: 0.4,
        pointBackgroundColor: 'rgb(37, 99, 235)',
        borderWidth: 3
      }]
    };

    const options = {
      ...getCommonOptions(chartRef.current?.getContext('2d')),
      plugins: {
        ...getCommonOptions().plugins,
        legend: {
          display: false
        }
      },
      scales: {
        ...getCommonOptions().scales,
        x: {
          ...getCommonOptions().scales.x,
          ticks: {
            color: '#1e40af'
          }
        },
        y: {
          ...getCommonOptions().scales.y,
          ticks: {
            color: '#1e40af',
            callback: function(value) {
              return value.toFixed(0);
            }
          }
        }
      }
    };

    return (
      <div className="transition-all duration-500 ease-in-out transform hover:scale-[1.01] h-full">
        <Line ref={chartRef} data={data} options={options} />
      </div>
    );
  };

  // Market Trends Chart component
  const MarketTrendsChart = () => {
    const chartRef = useRef(null);

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error} />;
    if (chartData.indexData.length === 0) return <LoadingSpinner />;

    // Make sure we have valid data to map
    const safeIndexData = chartData.indexData || [];

    // Process data for simple format
    const chartLabels = safeIndexData.map(d => d.date);
    const chartValues = safeIndexData.map(d => d.value);

    // Simple consistent format
    const data = {
      labels: chartLabels,
      datasets: [{
        label: 'Florida House Price Index',
        data: chartValues,
        borderColor: 'rgb(13, 148, 136)',
        backgroundColor: 'rgba(20, 184, 166, 0.2)',
        fill: true,
        tension: 0.4,
        pointBackgroundColor: 'rgb(13, 148, 136)',
        borderWidth: 3
      }]
    };

    const options = getCommonOptions(chartRef.current?.getContext('2d'), 'Historical House Price Index');

    return (
      <div className="transition-all duration-500 ease-in-out transform hover:shadow-lg h-full">
        <Line 
          ref={chartRef} 
          data={data} 
          options={options} 
        />
      </div>
    );
  };

  // Portfolio Performance Chart component
  const PortfolioPerformanceChart = () => {
    const chartRef = useRef(null);

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error} />;
    if (chartData.changeData.length === 0) return <LoadingSpinner />;

    // Make sure we have valid data to map
    const safeChangeData = chartData.changeData || [];

    // Process data for simple format
    const chartLabels = safeChangeData.map(d => d.date);
    const chartValues = safeChangeData.map(d => parseFloat(d.change.toFixed(2)));
    const chartColors = safeChangeData.map(d => 
      d.change >= 0 ? 'rgba(34, 197, 94, 0.7)' : 'rgba(239, 68, 68, 0.7)'
    );
    const chartBorders = safeChangeData.map(d => 
      d.change >= 0 ? 'rgb(22, 163, 74)' : 'rgb(220, 38, 38)'
    );

    // Simple consistent format
    const data = {
      labels: chartLabels,
      datasets: [{
        label: 'Year-over-Year Change (%)',
        data: chartValues,
        backgroundColor: chartColors,
        borderColor: chartBorders,
        borderWidth: 1,
        borderRadius: 4
      }]
    };

    const options = {
      ...getCommonOptions(chartRef.current?.getContext('2d'), 'Annual Growth Rate'),
      scales: {
        ...getCommonOptions().scales,
        y: {
          ...getCommonOptions().scales.y,
          ticks: {
            callback: function(value) {
              return value + '%';
            }
          }
        }
      }
    };

    return (
      <div className="transition-all duration-500 ease-in-out transform hover:shadow-lg h-full">
        <Bar ref={chartRef} data={data} options={options} />
      </div>
    );
  };

  // Market Trends Chart
  const MarketTrendsChartRecharts = ({ data }) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="gradientBlue" x1="0" y1="0" x2="0" y2="1">
              {gradients.blue.map((stop, index) => (
                <stop key={index} offset={stop.offset} stopColor={stop.color} />
              ))}
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis dataKey="date" stroke="#6B7280" fontSize={12} tickLine={false} />
          <YAxis stroke="#6B7280" fontSize={12} tickLine={false} tickFormatter={value => `$${value.toLocaleString()}`} />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="price"
            stroke={colors.primary}
            fill="url(#gradientBlue)"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  // Growth Analysis Chart
  const GrowthAnalysisChart = ({ data }) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis dataKey="type" stroke="#6B7280" fontSize={12} tickLine={false} />
          <YAxis stroke="#6B7280" fontSize={12} tickLine={false} tickFormatter={value => `${value}%`} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="growth" fill={colors.secondary} radius={[4, 4, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  // Demographics Charts
  const DemographicsCharts = {
    AgeDistribution: ({ data }) => {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill={colors.primary}
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={Object.values(colors)[index % Object.values(colors).length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      );
    },
    IncomeDistribution: ({ data }) => {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} layout="vertical" margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
            <XAxis type="number" stroke="#6B7280" fontSize={12} tickLine={false} tickFormatter={value => `$${value.toLocaleString()}`} />
            <YAxis dataKey="range" type="category" stroke="#6B7280" fontSize={12} tickLine={false} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value" fill={colors.info} radius={[0, 4, 4, 0]} />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  };

  return {
    HeroChart,
    MarketTrendsChart,
    PortfolioPerformanceChart,
    MarketTrendsChartRecharts,
    GrowthAnalysisChart,
    DemographicsCharts,
    loading,
    error
  };
};

export default Charts; 